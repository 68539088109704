import * as React from "react";

function CertifiedVerifiedIcon(props) {
  return (
    <svg
      width={props?.size || "34px"}
      height={props?.size || "34px"}
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <path
        d="M34 16.999c0 1.265-1.828 2.302-2.09 3.484-.271 1.222.93 2.965.41 4.067-.528 1.12-2.615 1.244-3.366 2.206-.75.962-.41 3.075-1.354 3.847-.945.772-2.872-.041-3.966.499-1.076.531-1.658 2.586-2.85 2.865-1.156.269-2.549-1.318-3.784-1.318-1.235 0-2.628 1.587-3.784 1.318-1.193-.277-1.774-2.332-2.852-2.865-1.094-.54-3.025.269-3.965-.499-.944-.772-.6-2.88-1.354-3.847-.753-.967-2.837-1.086-3.365-2.206-.529-1.12.682-2.846.41-4.067C1.817 19.262 0 18.263 0 16.999c0-1.265 1.828-2.302 2.09-3.484.272-1.222-.93-2.966-.41-4.067.528-1.12 2.615-1.244 3.366-2.206.75-.962.41-3.075 1.354-3.847.945-.773 2.872.04 3.966-.499 1.094-.54 1.658-2.588 2.85-2.866C14.41-.248 15.765 1.348 17 1.348c1.235 0 2.628-1.587 3.784-1.318 1.155.269 1.774 2.332 2.852 2.865 1.094.54 3.025-.269 3.965.498.94.768.6 2.881 1.354 3.848.753.966 2.837 1.086 3.365 2.206.52 1.101-.681 2.846-.41 4.067.263 1.183 2.091 2.22 2.091 3.484l-.001.001z"
        fill="#000"
      />
      <path
        d="M7.898 18.011l5.409 5.538L26.102 10.45"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default CertifiedVerifiedIcon;
