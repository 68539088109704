import { useState } from "react";
import {
  setImageFileData,
  setImageFileUrlData,
} from "reducers/ImageUploadReducer";
import closeBtn from "./close_button.svg";
import "./ImageSelector.css";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import styled from "styled-components";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";

export const Item = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 18px;
    background-image: linear-gradient(
      to bottom,
      rgba(170, 184, 190, 0),
      rgba(0, 247, 132, 0.8)
    );
    animation: scanning 0.8s linear infinite;
  }
  @keyframes scanning {
    100% {
      transform: ${(props) => {
        return `translate(0, ${props?.height - 10}px)`;
      }};
    }
  }
`;

const ImageSelector = ({ onChange }) => {
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state?.imageUploadState);
  const imgElement = useRef(null);
  const [height, setHeight] = useState(0);
  return (
    <>
      {!imageData?.currentFile ? (
        <div className="input-image">
          <input
            type="file"
            hidden
            style={{
              cursor: "pointer",
              position: "absolute",
              height: "100%",
              opacity: 0,
              width: "100%",
            }}
            accept="image/png, image/jpeg"
            onChange={(e) => {
              onChange(e);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <AddAPhotoOutlinedIcon fontSize="large" />
          <div className="ml-2 mt-2">Photo Identification</div>
          <div className="ml-2 mt-2 subText">
            {window.innerWidth > "480"
              ? "Click to upload a photo"
              : "Tap to upload or take a photo"}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className="parent">
            {imageData?.imageLoading ? <Item height={height} /> : <div></div>}
            <img
              ref={imgElement}
              src={imageData?.currentFileUrl}
              alt="service_dog_image"
              style={
                window.innerWidth > "480"
                  ? { width: "320px", height: "auto" }
                  : { width: "100%", height: "auto" }
              }
              onLoad={() => {
                setHeight(imgElement?.current?.height);
              }}
            />
            <img
              src={closeBtn}
              alt="replace"
              className="closeBtn"
              onClick={() => {
                dispatch(setImageFileData(""));
                dispatch(setImageFileUrlData(""));
              }}
            />{" "}
          </div>
        </div>
      )}
    </>
  );
};

export default ImageSelector;
