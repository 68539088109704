import * as React from "react";

function CertifiedVaccinationIcon(props) {
  return (
    <svg
      width={props?.size || "19px"}
      height={props?.size || "37px"}
      viewBox="0 0 19 37"
      fill="none"
      {...props}
    >
      <g fill="#000">
        <rect x={3.455} width={12.091} height={6.317} rx={1} />
        <path d="M1.727 8.122C.773 8.122 0 8.93 0 9.927v6.317h19V9.927c0-.997-.773-1.805-1.727-1.805H1.727zM19 28.878H0v6.317C0 36.192.773 37 1.727 37h15.546c.954 0 1.727-.808 1.727-1.805v-6.317z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.636 23.463v3.61h2v-3.61h3.182v-2h-3.182V18.05h-2v3.414H5.182v2h3.454z"
        />
      </g>
    </svg>
  );
}

export default CertifiedVaccinationIcon;
