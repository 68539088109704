import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";
import { Text } from "core-library/Text/Text";
import React from "react";

export default function LoadingCard({ type, textContent }) {
  return (
    <Box className="outer">
      <Flex
        background={type === "blackcard" ? "#000" : "#fff"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text
          fontFamily={"Saira"}
          fontSize={"25px"}
          fontWeight={700}
          color={type === "blackcard" ? "#fff" : "#000"}
        >
          {textContent}
        </Text>
      </Flex>
    </Box>
  );
}
