import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { magic } from "../../magic";
import Loading from "./Loading";
import { ROUTE_PATHNAME } from "App";

export default function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    // On mount, we try to login with a Magic credential in the URL query.
    magic.auth.loginWithCredential().finally(() => {
      navigate(ROUTE_PATHNAME.PROFILE);
    });
  }, []);

  return <Loading />;
}
