let API_URL = process.env.REACT_APP_API_URL || `https://test-api.nycservicedogcertification.com`
// let STRIPE_TEST_SUFFIX="#fidkdWxOYHwnPyd1blpxYHZxWjA0TzxPZEZAM0RDYkB2UlNVN3I3X1M0XDBMQlJGajFQM38ybH9sXDBPaDBraklfNmw9U1VyUGtOTGBsUF9nVnR2VmxzNnJcYG5XT0pramR1VU1xTnZWSDFoNTUxTzZSbXNwdycpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
// let STRIPE_SUFFIX="#fidkdWxOYHwnPyd1blppbHNgWjA0TzxPZEZAM0RDYkB2UlNVZkpyQEpdZExWRFJtaz1wSzdibjBTYmtTSzVzY2dKdExDNGFPNnBrajVxdGZofWlENUIyRkBpcFJiTUxiMT03bzBpYEM8clRUNTV3bWw9MG1QSScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"

// console.log(process.env.API_URL)
// switch (process.env.REACT_APP_ENV) {
//   case 'local':
//     API_URL = 'https://nzo3r095ak.execute-api.us-east-1.amazonaws.com'
//     STRIPE_SUFFIX = STRIPE_TEST_SUFFIX
//     break;
//   case 'test':
//     API_URL = 'https://nzo3r095ak.execute-api.us-east-1.amazonaws.com'
//     STRIPE_SUFFIX = STRIPE_TEST_SUFFIX
//     break;
//   case 'production':
//     API_URL = 'https://nzo3r095ak.execute-api.us-east-1.amazonaws.com'
//     STRIPE_SUFFIX = STRIPE_TEST_SUFFIX
//     break;
//   default:
//     API_URL = 'https://nzo3r095ak.execute-api.us-east-1.amazonaws.com'
//     STRIPE_SUFFIX = STRIPE_TEST_SUFFIX
// }

// API_URL = 'https://test-api.savannahservicedogs.com'
export { 
  API_URL,
  // STRIPE_SUFFIX
};
