import { cloneElement, useState, useCallback } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { magic } from "magic";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLoginData } from "reducers/LoginReducer";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const ElevationScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const withAppBar =
  ({ title, offsets = 1, route }) =>
  (WrappedComponent) =>
  (props) => {
    const dispatch = useDispatch();
    const loginData = useSelector((state) => state?.loginState?.loggedIn);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => {
      navigate(-1);
    };

    const logout = useCallback(() => {
      dispatch(setLoginData({ email: "", isLoggedIn: false }));
      localStorage?.setItem("isLoggedIn", false);
      magic.user.logout().then(() => {
        navigate("/login");
      });
    }, [navigate]);

    return (
      <div>
        <ElevationScroll {...props}>
          <AppBar position="fixed">
            <Container maxWidth="lg">
              <Toolbar sx={{ justifyContent: "center" }}>
                {!location?.pathname?.includes("profile") && (
                  <IconButton
                    sx={{
                      position: "absolute !important",
                      left: 9,
                    }}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                      if (route == "/success") {
                        if (loginData?.isLoggedIn) navigate("/profile");
                        else navigate("/");
                      } else if (route) {
                        return navigate(route);
                      } else {
                        return goBack();
                      }
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                )}
                <Typography variant="h6" component="div">
                  {title}
                </Typography>
                {loginData?.isLoggedIn && (
                  <>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 9,
                      }}
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        disabled={location.pathname.match(/^\/profile/)}
                        onClick={() => {
                          navigate("/profile/back");
                        }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem disabled onClick={handleClose}>My account</MenuItem>
                      <MenuItem onClick={logout}>Sign Out</MenuItem>
                    </Menu>
                  </>
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        {Array(offsets).fill(<Offset />)}
        <WrappedComponent {...props} />
      </div>
    );
  };

export default withAppBar;
