import * as React from "react";

function LocationIcon(props) {
  return (
    <svg
      width={props?.size || "44px"}
      height={props?.size || "44px"}
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <rect width={44} height={44} rx={22} fill="#E0E0E0" />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={10}
        y={10}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M10 10h24v24H10z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M22 31.325a2.07 2.07 0 01-.7-.125 1.84 1.84 0 01-.625-.375A39.112 39.112 0 0117.8 27.9c-.833-.95-1.53-1.87-2.088-2.762-.558-.892-.983-1.75-1.274-2.576C14.146 21.738 14 20.95 14 20.2c0-2.5.804-4.492 2.413-5.975C18.02 12.742 19.883 12 22 12c2.117 0 3.98.742 5.587 2.225C29.197 15.708 30 17.7 30 20.2c0 .75-.146 1.538-.438 2.363-.291.825-.716 1.683-1.274 2.575A21.678 21.678 0 0126.2 27.9a39.112 39.112 0 01-2.875 2.925 1.84 1.84 0 01-.625.375 2.07 2.07 0 01-.7.125zM22 25c.933 0 1.775-.23 2.525-.688A5.065 5.065 0 0026.3 22.5a6.402 6.402 0 00-1.975-1.113A6.947 6.947 0 0022 21c-.817 0-1.592.13-2.325.387A6.402 6.402 0 0017.7 22.5a5.065 5.065 0 001.775 1.813c.75.458 1.592.687 2.525.687zm0-5c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.413A1.926 1.926 0 0022 16c-.55 0-1.02.196-1.413.587A1.926 1.926 0 0020 18c0 .55.196 1.02.587 1.413.392.391.863.587 1.413.587z"
          fill="#464646"
        />
      </g>
    </svg>
  );
}

export default LocationIcon;
