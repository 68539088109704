import * as React from "react";

function NonVaccinationIcon(props) {
  return (
    <svg
      width={props?.size || "30px"}
      height={props?.size || "30px"}
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 15c0 6.075-4.925 11-11 11-2.56 0-4.915-.874-6.784-2.34L23.659 8.215A10.953 10.953 0 0126 15zM5.563 20.655L20.655 5.563A10.949 10.949 0 0015 4C8.925 4 4 8.925 4 15c0 2.068.57 4.003 1.563 5.655zM30 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15z"
        fill="#D30000"
      />
    </svg>
  );
}

export default NonVaccinationIcon;
