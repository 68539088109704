import { Box, BoxProps } from "core-library/Box/Box";
import React, { forwardRef, ReactElement } from "react";
// import { FormattedMessage } from "react-intl";

// import { LocaleString } from "./../../locales";
// import { Box, BoxProps } from "src/core-library/Box/Box";

export interface TextProps extends BoxProps {
  locale?: boolean;
  values?: Record<string, any>;
  as?: any;
  // id?: LocaleString;
  children?: any;
  cursor?: string;
  "data-testid"?: string;
  ref?: any;
  onClick?: (e: any) => void;
}

export const Text = forwardRef<ReactElement, TextProps>(
  ({ as, children, values, ...rest }, ref) => {
    // if (id) {
    //   return (
    //     <FormattedMessage id={id} values={values}>
    //       {(text) => (
    //         <Box as={as} ref={ref} {...rest}>
    //           {text}
    //         </Box>
    //       )}
    //     </FormattedMessage>
    //   );
    // }
    return (
      <Box as={as} ref={ref} {...rest}>
        {children}
      </Box>
    );
  }
);
Text.defaultProps = {
  as: "div",
  children: "",
};
