import { useState } from "react";
import { Button } from "@mui/material";
import "./UploadImage.css";
import ImageSelector from "./ImageSelector";
import { requestSignedUrl, saveFileToS3 } from "api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setImageFileData,
  setImageFileUrlData,
} from "reducers/ImageUploadReducer";

const ImageUploader = ({ onImageSelected, uploadAble = false }) => {
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state?.imageUploadState);

  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

  const upload = async () => {
    setUploadButtonDisabled(true);
    const signedUrl = await requestSignedUrl(imageData?.currentFile);
    await saveFileToS3(signedUrl, imageData?.currentFile);
    dispatch(setImageFileData(""));
    dispatch(setImageFileUrlData(""));
  };

  const setSelectedFileNames = (e) => {
    dispatch(setImageFileData(e.target.files[0]));
    dispatch(setImageFileUrlData(URL.createObjectURL(e.target.files[0])));
    setUploadButtonDisabled(false);
    onImageSelected(e);
  };

  return (
    <>
      <ImageSelector onChange={setSelectedFileNames} />
      {uploadAble && (
        <Button
          disabled={uploadButtonDisabled}
          className="btn-upload"
          onClick={upload}
          variant="contained"
          component="label"
        >
          Upload
        </Button>
      )}
    </>
  );
};

export default ImageUploader;
