import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageLoading: false,
  currentFile: "",
  currentFileUrl: "",
  serviceType: "",
  handlerEmail: "",
  dogDetails: {},
  signedUrl: "",
};

export const ApplicationReducerSlice = createSlice({
  name: "applicationState",
  initialState,
  reducers: {
    setApplicationImageLoadData: (state, action) => {
      state.imageLoading = action.payload;
    },
    setApplicationImageFileData: (state, action) => {
      state.currentFile = action.payload;
    },
    setApplicationImageFileUrlData: (state, action) => {
      state.currentFileUrl = action.payload;
    },
    setApplicationServiceTypeData: (state, action) => {
      state.serviceType = action.payload;
    },
    setApplicationHandlerEmailData: (state, action) => {
      state.handlerEmail = action.payload;
    },
    setApplicationDogDetailsData: (state, action) => {
      state.dogDetails = action.payload;
    },
    setApplicationSignedUrl: (state, action) => {
      state.signedUrl = action.payload;
    },
  },
});

export const {
  setApplicationImageLoadData,
  setApplicationImageFileData,
  setApplicationImageFileUrlData,
  setApplicationServiceTypeData,
  setApplicationHandlerEmailData,
  setApplicationDogDetailsData,
  setApplicationSignedUrl,
} = ApplicationReducerSlice.actions;

export const ApplicationState = (state) => state.applicationState;

export default ApplicationReducerSlice.reducer;
