import { useState, useEffect } from "react";
import { detectDogInImage, requestSignedUrl, saveFileToS3 } from "api";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import ImageUploader from "components/ImageUploader";
import FixedFooter from "components/FixedFooter";
import goToCheckout from "utilities/goToCheckout";
import { useSelector } from "react-redux";
import { API_URL } from "api/const";
import "./Application.css";
import { setGuestEmailData } from "reducers/LoginReducer";
import { useDispatch } from "react-redux";
import {
  setImageFileData,
  setImageFileUrlData,
  setImageLoadData,
} from "reducers/ImageUploadReducer";
import { Alert, Snackbar } from "@mui/material";
import { setPricesData } from "reducers/HomeReducer";

const ApplicationContainer = styled("div")({
  // flexDirection: 'column',
  // height: '100%',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  paddingBottom: "96px",
  paddingTop: "1.5rem",
});

const Application = (props) => {
  const loginData = useSelector((state) => state?.loginState?.loggedIn);
  const [emailValid, setEmailValid] = useState(
    loginData?.email?.length > 0 ? true : false
  );
  const [userEmail, setUserEmail] = useState(loginData?.email || "");
  const [disabled, setDisabled] = useState(true);
  const [dogName, setDogName] = useState();
  const [affirmationOfService, setAffirmationOfService] = useState(false);
  const [servicePerformed, setServicePerformed] = useState();
  const [dogAge, setDogAge] = useState();
  const [dogSex, setDogSex] = useState();
  const [dogSize, setDogSize] = useState("M");
  const [dogBreed, setDogBreed] = useState();
  const [dogColor, setColor] = useState();
  const [dogDescription, setDogDescription] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const pricesData = useSelector((state) => state?.pricesDataState?.pricesData);
  const priceDefault = pricesData?.filter((priceItem) => priceItem?.default);
  const imageData = useSelector((state) => state?.imageUploadState);
  const [snackBarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { open, vertical, horizontal } = snackBarState;
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getPrices = async () => {
      const response = await fetch(`${API_URL}/prices`);
      const data = await response.json();
      dispatch(
        setPricesData(data.sort((a, b) => a.priceInCents - b.priceInCents))
      );
    };
    getPrices();
  }, []);

  useEffect(() => {
    dispatch(setImageFileData(""));
    dispatch(setImageFileUrlData(""));
    dispatch(setImageLoadData(false));
  }, []);

  useEffect(() => {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (userEmail) {
      setEmailValid(emailRegex.test(userEmail));
    } else {
      setEmailValid(false);
    }
  }, [userEmail]);

  const setSelectedFileNames = (e) => {
    dispatch(setImageFileData(e.target.files[0]));
    dispatch(setImageFileUrlData(URL.createObjectURL(e.target.files[0])));
  };

  const handleClick = async () => {
    window.scrollTo(0, 0);
    setDisabled(true);
    dispatch(setGuestEmailData(userEmail));
    try {
      dispatch(setImageLoadData(true));
      const signedUrl = await requestSignedUrl(imageData?.currentFile);
      await saveFileToS3(signedUrl, imageData?.currentFile);
      const imageScan = await detectDogInImage(
        signedUrl.fields.bucket,
        signedUrl.fields.key
      );
      if (imageScan) {
        dispatch(setImageLoadData(false));
      }
      if (imageScan?.valid) {
        const data = {
          email: userEmail,
          imageBucket: signedUrl.fields.bucket,
          imageKey: signedUrl.fields.key,
          name: dogName,
          age: dogAge,
          service: servicePerformed,
          color: dogColor,
          description: dogDescription,
        };
        goToCheckout(data, ...priceDefault, location.state);
      } else {
        setErrorMessage(imageScan.reason);
        setSnackbarState({
          ...snackBarState,
          open: true,
        });
        dispatch(setImageFileData(""));
        dispatch(setImageFileUrlData(""));
        setDisabled(false);
      }
    } catch (e) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setSnackbarState({
          ...snackBarState,
          open: false,
        });
      }, 3000);
    }
  }, [open, snackBarState]);

  return (
    <>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {`We're sorry, but we are unable to use this photo to identify your Serivce Dog. Reason: ${errorMessage}. Please try again.`}
        </Alert>
      </Snackbar>
      <ApplicationContainer>
        <FormControl fullWidth>
          <ImageUploader onImageSelected={setSelectedFileNames} />
          {loginData?.email ? (
            <TextField
              disabled
              className="user-email"
              label="Your Email Address"
              defaultValue={loginData?.email}
              margin="normal"
            />
          ) : (
            <>
              <TextField
                error={userEmail && !emailValid}
                helperText={
                  userEmail && !emailValid ? "Invalid email address." : ""
                }
                margin="normal"
                label="Your Email Address"
                onChange={(e) => setUserEmail(e.target.value)}
                inputProps={{ style: { textTransform: "lowercase" } }}
              />
            </>
          )}
          <TextField
            margin="normal"
            label="Dog's Name"
            onChange={(e) => setDogName(e.target.value)}
          />
          <TextField
            margin="normal"
            label="Service that Dog Performs"
            onChange={(e) => setServicePerformed(e.target.value)}
          />
          <TextField
            type="number"
            margin="normal"
            label="Dog's Age"
            onChange={(e) => setDogAge(e.target.value)}
          />
          <TextField
            margin="normal"
            label="Dog Color"
            onChange={(e) => setColor(e.target.value)}
          />
          <TextField
            margin="normal"
            label="Dog Breed"
            onChange={(e) => setDogDescription(e.target.value)}
          />
          <TextField
            margin="normal"
            label="Description of Dog"
            onChange={(e) => setDogBreed(e.target.value)}
          />
        </FormControl>
      </ApplicationContainer>
      <FixedFooter
        handleClick={handleClick}
        buttonText="Submit"
        disabled={
          imageData?.imageLoading ||
          !(emailValid && imageData?.currentFile && dogName && servicePerformed)
        }
      />
    </>
  );
};

export default Application;
