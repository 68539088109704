import * as React from "react";

function CloseIcon(props) {
  return (
    <svg
      width={props?.size || "33px"}
      height={props?.size || "33px"}
      viewBox="0 0 33 33"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.968 15.704L31.55 2.121 29.43 0 15.845 13.583 2.876.613.755 2.732l12.97 12.971L0 29.429l2.121 2.121 13.725-13.725 14.338 14.338 2.121-2.122-14.337-14.337z"
        fill="#000"
      />
    </svg>
  );
}

export default CloseIcon;
