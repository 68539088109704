import React, { useState, useEffect } from "react";
import { API_URL } from "api/const";
import { useNavigate } from "react-router-dom";
import "./Products.css";
import { useDispatch } from "react-redux";
import { setPricesData } from "reducers/HomeReducer";
import { PRODUCTS_CONTENT, VERBIAGE_LABEL } from "utilities/constants";
import { ROUTE_PATHNAME } from "App";
import { API_URLS } from "utilities/constants/api";

export default function Pricing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const getPrices = async () => {
      const response = await fetch(`${API_URL}${API_URLS.FETCH_PRICES}`);
      const data = await response.json();
      setPrices(data.sort((a, b) => a.priceInCents - b.priceInCents));
      dispatch(
        setPricesData(data.sort((a, b) => a.priceInCents - b.priceInCents))
      );
    };
    getPrices();
  }, []);

  return (
    <section id="prices" className="container">
      {prices.map((product, index) => (
        <div
          onClick={() => {
            navigate(ROUTE_PATHNAME.APPLY, { state: product });
          }}
          className="columns"
          key={index}
        >
          <ul className="price2">
            <li className="header">{product.priceName}</li>
            <li className="grey">
              {`$${product.priceInCents / 100}`}{" "}
              <span>
                {product.recurring ? `/ ${product.recurring.interval}` : ""}
              </span>
            </li>
            <li>{PRODUCTS_CONTENT.MOBILE_QR_CODE}</li>
            <li>{PRODUCTS_CONTENT.UNLIMITED_SCANS}</li>
            <li>{product.priceDetails[1]}</li>
            <li>
              <span className="highlight">{product.priceDetails[2]}</span>
            </li>
            <li>{product.priceDetails[3]}</li>
            <li className="grey">
              <a className="button">{VERBIAGE_LABEL.SIGN_UP}</a>
            </li>
          </ul>
        </div>
      ))}
    </section>
  );
}
