import * as React from "react";

function NonCertifiedRectIcon(props) {
  return (
    <svg
      width={props?.size || "168px"}
      height={props?.size || "32px"}
      viewBox="0 0 142 142"
      fill="none"
      {...props}
    >
      <path
        fill="#0D0D0D"
        d="M.427 119.22L119.22.426l22.353 22.353L22.78 141.573z"
      />
    </svg>
  );
}

export default NonCertifiedRectIcon;
