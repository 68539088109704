import React from "react";
import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";
import LoaderGif from "./assets/Loader.gif";
import PolygonLeft from "./assets/PolygonLeft.png";
import { Text } from "core-library/Text/Text";
import "./LoaderCard.css";
import { VERBIAGE_LABEL, VERIFICATION_CONTENT } from "utilities/constants";

export default function LoaderCard() {
  return (
    <Box
      background={"#fff"}
      width={"100%"}
      height={"100%"}
      padding={"40px"}
      overflowX={"hidden"}
    >
      <Flex
        border="2px solid "
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        textAlign={"center"}
        alignItems={"center"}
        style={{
          borderImage: "linear-gradient(to bottom, #add2ff, #6676ff) 1",
        }}
        position="relative"
        className="loaderContainer"
        flexDirection={"column"}
      >
        <Flex justifyContent={"center"} alignItems={"center"} width="auto">
          <img
            src={LoaderGif}
            alt={VERBIAGE_LABEL.LOADING}
            width={"auto"}
            className="loaderImage"
          />
        </Flex>
        <Text
          position={"absolute"}
          bottom={150}
          fontWeight={600}
          color="#000"
          className="loaderContent"
        >
          {VERIFICATION_CONTENT.SEARCHING_RECORDS}
        </Text>
      </Flex>
      <img
        src={PolygonLeft}
        alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
        height="120px"
        style={{
          position: "absolute",
          right: -65,
          bottom: "50%",
          transform: "rotate(230deg)",
        }}
      />
      <img
        src={PolygonLeft}
        alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
        style={{ position: "absolute", bottom: 0, left: 0 }}
      />
    </Box>
  );
}
