import { configureStore } from "@reduxjs/toolkit";
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoginReducer from "reducers/LoginReducer";
import { combineReducers } from "redux";
import ProfileReducer from "reducers/ProfileReducer";
import HomeReducer from "reducers/HomeReducer";
import ImageUploadReducer from "reducers/ImageUploadReducer";
import ApplicationReducer from "reducers/ApplicationReducer";

const reducers = combineReducers({
  loginState: LoginReducer,
  productsDataState: ProfileReducer,
  pricesDataState: HomeReducer,
  imageUploadState: ImageUploadReducer,
  applicationState: ApplicationReducer,
});

const migrations = {
  0: (state) => {
    return {
      ...state,
      productsDataState: {
        productsData: state.productsDataState.productsData,
        applications: []
      }
    }
  }
}

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  migrate: createMigrate(migrations, { debug: true })
};

const persistedReducer = persistReducer(persistConfig, reducers);

const logger = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action);
      if (window.location.hostname === 'localhost' || window.location.hostname.match(/^test\./)) {
        console.log('Action: ', action)
        console.log("Updated State", store.getState());
      }
      return result;
    };
  };
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: [logger],
});

export default store;
