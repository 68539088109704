import * as React from "react";

function BellIcon(props) {
  return (
    <svg
      width={props?.size || "44px"}
      height={props?.size || "44px"}
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <rect width={44} height={44} rx={22} fill={props?.fill || "#2E2E2E"} />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={7}
        y={7}
        width={30}
        height={30}
      >
        <path fill="#464646" d="M7 7h30v30H7z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M13.25 30.75a1.21 1.21 0 01-.89-.36 1.21 1.21 0 01-.36-.89c0-.354.12-.651.36-.89.239-.24.536-.36.89-.36h1.25V19.5c0-1.73.52-3.266 1.563-4.61 1.041-1.343 2.395-2.223 4.062-2.64v-.875c0-.52.182-.963.547-1.328A1.808 1.808 0 0122 9.5c.52 0 .963.182 1.328.547.365.364.547.807.547 1.328v.875c1.667.417 3.02 1.297 4.063 2.64 1.041 1.344 1.562 2.88 1.562 4.61v8.75h1.25c.354 0 .651.12.89.36.24.239.36.536.36.89s-.12.651-.36.89c-.239.24-.536.36-.89.36h-17.5zM22 34.5a2.407 2.407 0 01-1.766-.734A2.407 2.407 0 0119.5 32h5c0 .688-.245 1.276-.734 1.766-.49.49-1.078.734-1.766.734zm-1.25-11.25v1.25c0 .354.12.651.36.89.239.24.536.36.89.36s.651-.12.89-.36c.24-.239.36-.536.36-.89v-1.25h1.25c.354 0 .651-.12.89-.36.24-.239.36-.536.36-.89s-.12-.651-.36-.89a1.21 1.21 0 00-.89-.36h-1.25V19.5c0-.354-.12-.651-.36-.89a1.21 1.21 0 00-.89-.36c-.354 0-.651.12-.89.36-.24.239-.36.536-.36.89v1.25H19.5c-.354 0-.651.12-.89.36-.24.239-.36.536-.36.89s.12.651.36.89c.239.24.536.36.89.36h1.25z"
          fill={props?.innerFill || "#fff"}
        />
      </g>
    </svg>
  );
}

export default BellIcon;
