import "./Contact.css";
import { useState } from "react";
import emailjs from "emailjs-com";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { CONTACT_CONTENT, VERBIAGE_LABEL } from "utilities/constants";

const initialState = {
  name: "",
  email: "",
  message: "",
};
const Contact = () => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [snackBarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { open, vertical, horizontal } = snackBarState;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3txx0l5", //  SERVICE_ID
        "template_lfy0v7q", // TEMPLATE_ID
        e.target,
        "hIVMu47o7Pl9CjiEF" // PUBLIC_KEY
      )
      .then(
        (result) => {
          setLoading(false);
          setState({
            name: "",
            email: "",
            message: "",
          });
          setSuccessMessage(true);
        },
        (error) => {
          setSnackbarState({
            ...snackBarState,
            open: true,
          });
        }
      );
  };

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(false), 3000);
    }
  }, [successMessage]);

  return (
    <>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {VERBIAGE_LABEL.ERROR_MESSAGE}
        </Alert>
      </Snackbar>
      <section id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{CONTACT_CONTENT.HEADER}</h2>
                <p>
                  {CONTACT_CONTENT.TEXT}
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="hidden"
                        id="hostname"
                        name="hostname"
                        className="form-control"
                        value={window.location.hostname}
                      />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={VERBIAGE_LABEL.NAME}
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={VERBIAGE_LABEL.EMAIL_ADDRESS}
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={VERBIAGE_LABEL.MESSAGE}
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                {!loading ? (
                  <button
                    type="submit"
                    className="btn btn-custom btn-lg"
                    style={{
                      background: successMessage ? "green" : "transparent",
                    }}
                  >
                    {successMessage ? VERBIAGE_LABEL.SENT : VERBIAGE_LABEL.SEND_MESSAGE}
                  </button>
                ) : (
                  <div
                    style={{
                      background: "white",
                      width: "200px",
                      height: "50px",
                      borderRadius: "100px",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      bgcolor="red"
                      sx={{
                        width: "200px",
                        height: "50px",
                        borderRadius: "100px",
                      }}
                      loading
                    >
                      {VERBIAGE_LABEL.NEXT}
                    </LoadingButton>
                  </div>
                )}
              </form>
            </div>
          </div>
          {/* <div className='col-md-3 col-md-offset-1 contact-info'> */}
          {/*   <div className='contact-item'> */}
          {/*     <h3>Contact Info</h3> */}
          {/*     <p> */}
          {/*       <span> */}
          {/*         <i className='fa fa-map-marker'></i> Address */}
          {/*       </span> */}
          {/*       {props.data ? props.data.address : 'loading'} */}
          {/*     </p> */}
          {/*   </div> */}
          {/*   <div className='contact-item'> */}
          {/*     <p> */}
          {/*       <span> */}
          {/*         <i className='fa fa-phone'></i> Phone */}
          {/*       </span>{' '} */}
          {/*       {props.data ? props.data.phone : 'loading'} */}
          {/*     </p> */}
          {/*   </div> */}
          {/*   <div className='contact-item'> */}
          {/*     <p> */}
          {/*       <span> */}
          {/*         <i className='fa fa-envelope-o'></i> Email */}
          {/*       </span>{' '} */}
          {/*       {props.data ? props.data.email : 'loading'} */}
          {/*     </p> */}
          {/*   </div> */}
          {/* </div> */}
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={CONTACT_CONTENT.FACEBOOK || "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={CONTACT_CONTENT.TWITTER || "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={CONTACT_CONTENT.YOUTUBE || "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; {VERBIAGE_LABEL.YEAR} {window.location.host}</p>
        </div>
      </div>
    </>
  );
};

export default Contact;
