import React, { useState, useEffect } from "react";
import VerificationCard from "./VerificationCard";
import { Box } from "core-library/Box/Box";
import { Text } from "core-library/Text/Text";
import { Flex } from "core-library/Flex/Flex";
import CertifiedIcon from "assets/CertifiedIcon";
import CertifiedVaccinationIcon from "assets/CertifiedVaccinationIcon";
import CertifiedVerifiedIcon from "assets/CertifiedVerifiedIcon";
import NonVerifiedIcon from "assets/NonVerifiedIcon";
import NonVaccinationIcon from "assets/NonVaccinationIcon";
import NonCertifiedIcon from "assets/NonCertifiedIcon";
import NonCertifiedRectIcon from "assets/NonCertifiedRectIcon";
import Cirecle from "assets/Cirecle";
import CloseIcon from "assets/CloseIcon";
import { useNavigate } from "react-router-dom";
import Modal from "components/Verification/Modal/Modal";
import { VERBIAGE_LABEL } from "utilities/constants";

export default function VerificationDetail({ image, serviceDog, expired }) {
  const [isOpen, setIsOpen] = useState(expired);
  const navigate = useNavigate();

  const flexDirection = ((serviceDog?.OwnerName || '') + (serviceDog?.Service || '')).length > 25 ? 'column' : 'row'
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isOpen]);

  return (
    <Box>
      <Flex
        width={"100%"}
        justifyContent={"center"}
        my="25px"
        cursor="pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        <CloseIcon />
      </Flex>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
      <VerificationCard>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          padding="44px 38px"
          height={"100%"}
        >
          <Box width="266px" position="relative">
            <img
              src={image}
              style={{
                maxWidth: "100%",
                borderRadius: "12px",
              }}
              alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
              className={expired && "imgGrey"}
            />
            {expired && (
              <Box
                position="absolute"
                top="25%"
                width="100%"
                background={"#000"}
              >
                <Flex height={"5px"} background={"#D30000"} width={"100%"} />
                <Text
                  fontSize={"62px"}
                  fontFamily={"Top Secret"}
                  color="#D30000"
                >
                  {VERBIAGE_LABEL.EXPIRED}
                </Text>
                <Flex height={"5px"} background={"#D30000"} width={"100%"} />
              </Box>
            )}
          </Box>
          <Flex flexDirection={"column"} my="27px" alignItems={"center"}>
            <Text
              color={expired ? "#D30000" : "#fff"}
              textTransform="uppercase"
              fontFamily={"Saira Stencil One"}
              fontSize={"48px"}
              mb="12px"
              textAlign={'center'}
            >
              {serviceDog?.Name || VERBIAGE_LABEL.DEFAULT_DOG_NAME}
            </Text>
            <Flex
              mb="30px"
              gap="6px"
              flexDirection={flexDirection}
              fontSize={"16px"}
              fontFamily={"Saira"}
              color="rgba(255, 255, 255, 0.40)"
              alignItems={"center"}
            >
              <Text>{serviceDog?.Service || VERBIAGE_LABEL.GUIDE_DOG}</Text>
              <Cirecle />
              <Text textTransform="capitalize">
                {serviceDog?.OwnerName || VERBIAGE_LABEL.DEFAULT_OWNER_NAME}
              </Text>
            </Flex>
            {expired ? (
              <Box position={"relative"}>
                <NonCertifiedIcon />{" "}
                <Box
                  position="absolute"
                  top={40}
                  left={'-18px'}
                  background={"#000"}
                  style={{ transform: "rotate(-50deg" }}
                >
                  <NonCertifiedRectIcon />
                </Box>{" "}
              </Box>
            ) : (
              <CertifiedIcon />
            )}
          </Flex>
        </Flex>
      </VerificationCard>
      <Flex width="100%" justifyContent={"center"} className="bottomCard">
        <Box
          borderRadius={"12px"}
          border="2px solid black"
          padding="14px 28px"
          my="25px"
          width="342px"
        >
          <Flex ml={!expired && "5px"} gap="26px" alignItems={"center"}>
            {expired ? <NonVaccinationIcon /> : <CertifiedVaccinationIcon />}{" "}
            <Text
              fontSize={"16px"}
              fontFamily={"Saira"}
              textTransform="capitalize"
              color="#000"
            >
              {serviceDog?.Name || VERBIAGE_LABEL.DEFAULT_DOG_NAME} {VERBIAGE_LABEL.IS}{" "}
              {expired && (
                <Text
                  as="span"
                  color="#D30000"
                  fontFamily={"Saira"}
                  fontSize={"16px"}
                  fontWeight={900}
                >
                  {VERBIAGE_LABEL.NOT}{" "}
                </Text>
              )}
              {VERBIAGE_LABEL.VACCINATED}
            </Text>{" "}
          </Flex>
          <Flex
            gap="19px"
            alignItems={"center"}
            mt="25px"
            textTransform="capitalize"
          >
            {expired ? <NonVerifiedIcon /> : <CertifiedVerifiedIcon />}{" "}
            <Text fontSize={"16px"} fontFamily={"Saira"} color="#000">
              {serviceDog?.OwnerName || "John Doe"} is{" "}
              {expired && (
                <Text
                  as="span"
                  color="#D30000"
                  fontFamily={"Saira"}
                  fontSize={"16px"}
                  fontWeight={900}
                >
                  {VERBIAGE_LABEL.NOT}{" "}
                </Text>
              )}
              {VERBIAGE_LABEL.VERIFIED}
            </Text>{" "}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
