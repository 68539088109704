import LoaderCard from "components/Verification/Flows/SavannahServiceDogs/LoaderCard";
import React from "react";
import { useEffect, useState } from "react";
import ApplicationFlow from "./ApplicationFlow";
import { APPLICATION_CONTENT } from "utilities/constants";

export default function ApplicationNew() {
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingData(false);
    }, 2500);
  }, []);

  return (
    <>
      {loadingData ? (
        <LoaderCard text={APPLICATION_CONTENT.LOADER_TEXT} />
      ) : (
        <ApplicationFlow />
      )}
    </>
  );
}
