import * as React from "react";

function CalendarIcon(props) {
  return (
    <svg
      width={props?.size || "44px"}
      height={props?.size || "44px"}
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <rect width={44} height={44} rx={22} fill="#E0E0E0" />
      <path
        d="M15 31c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0113 29V15c0-.55.196-1.02.588-1.412A1.926 1.926 0 0115 13h1v-1c0-.283.096-.52.288-.713A.968.968 0 0117 11c.283 0 .52.096.712.287.192.192.288.43.288.713v1h8v-1c0-.283.096-.52.288-.713A.968.968 0 0127 11c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v5c0 .283-.096.52-.288.712A.968.968 0 0130 21a.968.968 0 01-.712-.288A.968.968 0 0129 20v-1H15v10h6c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0121 31h-6zm14 2c-1.067 0-2.02-.296-2.863-.888a4.949 4.949 0 01-1.812-2.312.508.508 0 01.063-.538.574.574 0 01.487-.262c.233 0 .446.07.637.212.192.142.346.321.463.538a3.4 3.4 0 001.25 1.275A3.41 3.41 0 0029 31.5c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0032.5 28c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0029 24.5a3.404 3.404 0 00-2.45 1h.7a.728.728 0 01.75.75.728.728 0 01-.75.75H25a.968.968 0 01-.712-.288A.968.968 0 0124 26v-2.25a.728.728 0 01.75-.75.728.728 0 01.75.75v.675c.45-.433.975-.78 1.575-1.038A4.816 4.816 0 0129 23c1.383 0 2.563.488 3.538 1.462C33.513 25.438 34 26.617 34 28s-.487 2.563-1.462 3.538C31.563 32.513 30.383 33 29 33z"
        fill="#464646"
      />
    </svg>
  );
}

export default CalendarIcon;
