import compose from 'utilities/compose';
import withAppBar from './withAppBar'
import withContainer from './withContainer'
import withAuthentication from './withAuthentication'

export default (options) => compose(
  withAuthentication,
  withAppBar(options),
  withContainer,
)
