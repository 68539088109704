import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Apply from "pages/Apply/Apply";
import Home from "pages/Home/Home";
import Success from "pages/Success/Success";
import Login from "pages/Login";
import Profile from "pages/Profile/Profile";
import Document from "pages/Document";
import Callback from "components/Magic/Callback";

import theme from "theme";
import { ThemeProvider } from "@mui/material/styles";
import ProfileBack from "pages/Profile/ProfileBack";

import Verify from "components/Verification/Verify";
import ApplicationNew from "components/ApplicationNew.js/ApplicationNew";

export const ROUTE_PATHNAME = {
    HOME: '/',
    APPLY: '/apply',
    SUCCESS: '/success',
    VERIFICATION: '/verification',
    DOCUMENTS: '/documents',
    LOGIN: '/login',
    CALLBACK: '/callback',
    PROFILE: '/profile',
    PROFILE_BACK: '/profile/back'
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path={ROUTE_PATHNAME.HOME} element={<Home />} />
          <Route path={ROUTE_PATHNAME.APPLY} element={<ApplicationNew />} />
          <Route path={ROUTE_PATHNAME.SUCCESS} element={<Success />} />
          <Route path={ROUTE_PATHNAME.VERIFICATION} element={() => {}} />
          <Route path={`${ROUTE_PATHNAME.VERIFICATION}/:id`} element={<Verify />} />
          <Route path={`${ROUTE_PATHNAME.DOCUMENTS}/:id`} element={<Document />} />
          <Route path={ROUTE_PATHNAME.LOGIN} element={<Login />} />
          <Route path={ROUTE_PATHNAME.CALLBACK} element={<Callback />} />
          <Route path={ROUTE_PATHNAME.PROFILE} element={<Profile />} />
          <Route path={ROUTE_PATHNAME.PROFILE_BACK} element={<ProfileBack />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
