import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import {
  setApplicationImageLoadData,
  setApplicationSignedUrl,
} from "reducers/ApplicationReducer";
import UploadIcon from "../assets/UploadIcon";
import { Text } from "core-library/Text/Text";
import { Flex } from "core-library/Flex/Flex";
import { detectDogInImage, requestSignedUrl, saveFileToS3 } from "api";
import { Box } from "core-library/Box/Box";
import Success from "../assets/Success";
import ImageLoading from "./ImageLoading.gif";
import { VERBIAGE_LABEL } from "utilities/constants";

const ImageSelector = ({ onChange, setDisable }) => {
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state?.applicationState);
  const imgElement = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState("");

  const handleClick = useCallback(async () => {
    setErrorMessage("");
    setUploadSuccess("");
    window.scrollTo(0, 0);
    setDisable(true);
    try {
      const signedUrl = await requestSignedUrl(imageData?.currentFile);
      dispatch(setApplicationSignedUrl(signedUrl));
      if(signedUrl)
      {
      await saveFileToS3(signedUrl, imageData?.currentFile);
      const imageScan = await detectDogInImage(
        signedUrl.fields.bucket,
        signedUrl.fields.key
      );
      if (imageScan) {
        dispatch(setApplicationImageLoadData(false));
      }
      if (imageScan?.valid) {
        setErrorMessage("");
        setUploadSuccess(VERBIAGE_LABEL.IMAGE_UPLOAD_SUCCESS);
        setDisable(false);
      } else {
        setErrorMessage(imageScan?.reason);
        setDisable(true);
        setUploadSuccess("");
      }
    }
    } catch (e) {}
  }, [imageData?.currentFile]);

  useEffect(() => {
    if (imageData?.currentFile) {
      dispatch(setApplicationImageLoadData(true));
      handleClick();
    }
  }, [dispatch, handleClick, imageData?.currentFile]);

  return (
    <Box my="20px" mb={uploadSuccess || errorMessage ? "100px" : "200px"}>
      <Flex
        mt="20px"
        width="350px"
        height="200px"
        borderRadius={"4px"}
        border={!imageData?.currentFile && "2px dashed #A4B0C0"}
        justifyContent={"center"}
        alignItems={"center"}
        cursor="pointer"
      >
        {!imageData?.currentFile ? (
          <Flex
            flexDirection={"column"}
            alignItems="center"
            justifyContent={"center"}
            width="100%"
            height="100%"
          >
            <input
              type="file"
              hidden
              style={{
                cursor: "pointer",
                position: "absolute",
                height: "100%",
                opacity: 0,
                width: "100%",
              }}
              accept="image/png, image/jpeg"
              onChange={(e) => {
                onChange(e);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <UploadIcon />
            <Text color="#144185" fontSize={"14px"} fontWeight={600}>
              {VERBIAGE_LABEL.UPLOAD_PHOTO}
            </Text>
          </Flex>
        ) : (
          <>
            {imageData?.imageLoading ? (
              <img
                src={ImageLoading}
                alt={VERBIAGE_LABEL.LOADING}
                width={"auto"}
                className="loaderImage"
              />
            ) : (
              <Flex
                flexDirection={"column"}
                alignItems="center"
                justifyContent={"center"}
                width="100%"
                height="100%"
              >
                <img
                  ref={imgElement}
                  src={imageData?.currentFileUrl}
                  alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
                  style={{ width: "100%", height: "100%", borderRadius: "4px" }}
                />
              </Flex>
            )}
          </>
        )}
      </Flex>
      {errorMessage && (
        <Flex
          color="#791E15"
          fontSize={"16px"}
          fontFamily={"SF Pro Display"}
          fontWeight={600}
          padding={"8px 14px"}
          my="20px"
          background={"#FFF6F5"}
          borderRadius={"2px"}
          justifyContent={"center"}
        >
          {`${VERBIAGE_LABEL.PLEASE_TRY_AGAIN}. ${errorMessage}`}
        </Flex>
      )}
      {uploadSuccess && (
        <Flex
          color="#1D5A30"
          fontSize={"16px"}
          fontFamily={"SF Pro Display"}
          fontWeight={600}
          padding={"8px 14px"}
          my="20px"
          background={"#E7F4EB"}
          borderRadius={"2px"}
          gap="10px"
          alignItems={"center"}
        >
          <Success />
          {uploadSuccess}
        </Flex>
      )}
      {(uploadSuccess || errorMessage) && (
        <Flex
          mt="20px"
          width="350px"
          height="200px"
          borderRadius={"4px"}
          border={"2px dashed #A4B0C0"}
          justifyContent={"center"}
          alignItems={"center"}
          cursor="pointer"
        >
          <Flex
            flexDirection={"column"}
            alignItems="center"
            justifyContent={"center"}
            width="100%"
            height="100%"
          >
            <input
              type="file"
              hidden
              style={{
                cursor: "pointer",
                position: "absolute",
                height: "100%",
                opacity: 0,
                width: "100%",
              }}
              accept="image/png, image/jpeg"
              onChange={(e) => {
                onChange(e);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <UploadIcon />
            <Text color="#394A64" fontSize={"14px"} fontWeight={600}>
              {VERBIAGE_LABEL.REPLACE_PHOTO}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default ImageSelector;
