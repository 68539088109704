import { Flex } from "core-library/Flex/Flex";
import { Box } from "core-library/Box/Box";
import { Text } from "core-library/Text/Text";
import FormikInput from "core-library/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationHandlerEmailData } from "reducers/ApplicationReducer";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { setGuestEmailData } from "reducers/LoginReducer";
import { APPLICATION_CONTENT, VALIDATIONS, VERBIAGE_LABEL } from "utilities/constants";

const AddServiceTypeValidationSchema = yup.object().shape({
  email: yup.string().trim().email(VALIDATIONS.INVALID_EMAIL).required(VALIDATIONS.REQUIRED),
});

export default function Step3({ setStep }) {
  const dispatch = useDispatch();
  const handlerEmail = useSelector(
    (state) => state?.applicationState?.handlerEmail
  );

  return (
    <Formik
      initialValues={{
        email: handlerEmail || "",
      }}
      onSubmit={() => {}}
      enableReinitialize
      validationSchema={AddServiceTypeValidationSchema}
    >
      {({ values }) => {
        return (
          <Form>
            <Flex
              background={"#fff"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"80%"}
            >
              <Flex width="100%" p="20px" justifyContent="center">
                <Flex flexDirection={"column"}>
                  <Text
                    color="#051226"
                    fontSize={"20px"}
                    fontWeight={600}
                    fontFamily={"SF Pro Display"}
                  >
                    {APPLICATION_CONTENT.HANDLERS_EMAIL_TEXT}
                  </Text>

                  <Box mt="30px" mb="50px">
                    <FormikInput
                      label={APPLICATION_CONTENT.HANDLERS_EMAIL_LABLEL}
                      name="email"
                      variant="outlined"
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex
                gap="20px"
                py="30px"
                borderTop={"1px solid #D79791"}
                justifyContent={"center"}
              >
                <Flex
                  width={"160px"}
                  height={"60px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor="pointer"
                  borderRadius={"6px"}
                  border={"1px solid #D6BCB9"}
                  fontSize={"18px"}
                  fontFamily={"Roboto"}
                  fontWeight={600}
                  color="#832F27"
                  onClick={() => setStep(2)}
                >
                  {VERBIAGE_LABEL.BACK}
                </Flex>
                <Flex
                  width={"200px"}
                  height={"60px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor={!values?.email ? "not-allowed" : "pointer"}
                  borderRadius={"6px"}
                  border={"1px solid #BF7F79"}
                  background={"#F2E5E4"}
                  fontSize={"18px"}
                  fontFamily={"Roboto"}
                  fontWeight={600}
                  color="#832F27"
                  onClick={() => {
                    if (values?.email) {
                      dispatch(setApplicationHandlerEmailData(values?.email));
                      dispatch(setGuestEmailData(values?.email));
                      setStep(4);
                    }
                  }}
                  opacity={!values?.email && "0.4"}
                >
                  {VERBIAGE_LABEL.CONTINUE}
                </Flex>
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
