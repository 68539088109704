import { Box } from "core-library/Box/Box";
import React from "react";

export default function Tag({ text, bgcolor, width, fontSize, letterSpacing }) {
  return (
    <Box
      borderRadius={"100px"}
      p="7px 14px"
      background={bgcolor || "#9C4B43"}
      fontFamily={"SF Pro Display"}
      fontSize={fontSize || "14px"}
      fontWeight={600}
      letterSpacing={letterSpacing || "0.56px"}
      width={width || "max-content"}
      textAlign={"center"}
      color="#F6F9FD"
    >
      {text}
    </Box>
  );
}
