import { useState } from "react";
import Container from "@mui/material/Container";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const FixedFooter = ({ handleClick, buttonText, disabled }) => {
  const imageData = useSelector((state) => state?.imageUploadState);
  const [loading, setLoading] = useState(false);
  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      handleClick();
    }
  };

  useEffect(() => {
    if (!imageData?.currentFile) {
      setLoading(false);
    }
  }, [imageData?.currentFile]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "white",
        width: "100%",
        padding: "1.5rem 0",
        zIndex: 99,
        boxShadow: "0px 5px 4px 3px black",
      }}
    >
      <Container>
        <Button
          fullWidth
          variant="contained"
          disabled={disabled}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default FixedFooter;
