import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productsData: [],
  applications: [],
};

export const ProfileReducerSlice = createSlice({
  name: "productsDataState",
  initialState,
  reducers: {
    setProductsData: (state, action) => {
      state.productsData = action.payload;
    },
    addDog: (state, action) => {
      state.productsData.push(action.payload);
    },
    updateDog: (state, action) => {
      let dogs = [...state.productsData];
      const dog = dogs.findIndex(p => p.sk === action.payload.sk);
      if (dog >= 0) {
        dogs[dog] = action.payload
      }
      state.productsData = dogs;
    },
    addApplication: (state, action) => {
      state.applications.push(action.payload);
    }
  },
});
export const { setProductsData, addDog, updateDog, addApplication } = ProfileReducerSlice.actions;
export const ProductsDataState = (state) => state.productsDataState;

export default ProfileReducerSlice.reducer;
