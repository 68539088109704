import styled, { css as sCss } from "styled-components";
import { Box, BoxProps } from "core-library/Box/Box";
interface FlexProps extends BoxProps {
  gap?: string;
}

export const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${({ gap }) =>
    gap &&
    sCss`
      gap: ${gap};
    `}
`;
