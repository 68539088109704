import * as React from "react";

function UploadIcon(props) {
  return (
    <svg
      width={props?.size || "36px"}
      height={props?.size || "36px"}
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    >
      <path
        d="M29.025 15.06C28.005 9.885 23.46 6 18 6c-4.335 0-8.1 2.46-9.975 6.06A8.991 8.991 0 000 21c0 4.965 4.035 9 9 9h19.5c4.14 0 7.5-3.36 7.5-7.5 0-3.96-3.075-7.17-6.975-7.44zM21 19.5v6h-6v-6h-4.5L18 12l7.5 7.5H21z"
        fill="#A4B0C0"
      />
    </svg>
  );
}

export default UploadIcon;
