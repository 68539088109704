import * as React from "react";

function HandlerIcon(props) {
  return (
    <svg
      width={props?.size || "24px"}
      height={props?.size || "24px"}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M11.875 20a.58.58 0 00.35-.15l8.2-8.2c.2-.2.346-.425.438-.675a2.165 2.165 0 000-1.512 1.652 1.652 0 00-.438-.638l-4.25-4.25a1.651 1.651 0 00-.638-.438 2.132 2.132 0 00-1.512 0c-.25.092-.475.238-.675.438l-.275.275 1.85 1.875c.25.233.433.5.55.8.117.3.175.617.175.95 0 .7-.238 1.287-.713 1.763-.474.475-1.062.712-1.762.712-.333 0-.654-.058-.963-.175a2.276 2.276 0 01-.812-.525L9.525 8.4 5.15 12.775a.47.47 0 00-.15.35c0 .133.05.254.15.363a.444.444 0 00.55.112.582.582 0 00.15-.1l3.4-3.4 1.4 1.4-3.375 3.4a.47.47 0 00-.15.35c0 .133.05.25.15.35.1.1.217.15.35.15a.582.582 0 00.35-.15l3.4-3.375 1.4 1.4-3.375 3.4a.3.3 0 00-.113.15.566.566 0 00-.037.2c0 .133.05.25.15.35a.48.48 0 00.7 0l3.4-3.375 1.4 1.4-3.4 3.4a.47.47 0 00.012.7c.109.1.23.15.363.15zm-.025 2a2.44 2.44 0 01-1.637-.613 2.386 2.386 0 01-.838-1.537 2.465 2.465 0 01-1.425-.7 2.465 2.465 0 01-.7-1.425 2.374 2.374 0 01-1.413-.713A2.543 2.543 0 015.15 15.6a2.377 2.377 0 01-1.55-.825c-.4-.467-.6-1.017-.6-1.65 0-.333.062-.654.187-.963.125-.308.305-.579.538-.812l5.8-5.775L12.8 8.85a.3.3 0 00.15.113.566.566 0 00.2.037c.15 0 .275-.046.375-.137.1-.092.15-.213.15-.363a.566.566 0 00-.038-.2.3.3 0 00-.112-.15L9.95 4.575a1.652 1.652 0 00-.638-.438 2.132 2.132 0 00-1.512 0c-.25.092-.475.238-.675.438L3.6 8.125c-.15.15-.275.325-.375.525-.1.2-.167.4-.2.6a1.888 1.888 0 00.2 1.2l-1.45 1.45a3.976 3.976 0 01-.625-1.263 3.928 3.928 0 01.2-2.75c.2-.441.475-.837.825-1.187L5.7 3.175A4.22 4.22 0 017.037 2.3a3.98 3.98 0 013.025 0c.492.2.93.492 1.313.875l.275.275.275-.275a4.22 4.22 0 011.337-.875 3.98 3.98 0 013.025 0c.492.2.93.492 1.313.875L21.825 7.4A4.074 4.074 0 0123 10.25c0 .517-.1 1.02-.3 1.512-.2.492-.492.93-.875 1.313l-8.2 8.175a2.593 2.593 0 01-.813.55c-.308.133-.629.2-.962.2z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default HandlerIcon;
