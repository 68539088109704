import { RadioButton } from "core-library/RadioButton/RadioButton";
import { Formik, Form } from "formik";
import * as yup from "yup";
import React from "react";
import { Flex } from "core-library/Flex/Flex";
import { Box } from "core-library/Box/Box";
import { Text } from "core-library/Text/Text";
import FormikInput from "core-library/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationServiceTypeData } from "reducers/ApplicationReducer";
import { APPLICATION_CONTENT, SERVICE_TYPE_OPTIONS, VERBIAGE_LABEL } from "utilities/constants";

const AddServiceTypeValidationSchema = yup.object().shape({});

export default function Step1({ setStep }) {
  const dispatch = useDispatch();
  const serviceTypeData = useSelector(
    (state) => state?.applicationState?.SERVICE_TYPE_OPTIONS
  );

  return (
    <Box background={"#fff"}>
      <Formik
        initialValues={{
          audience: SERVICE_TYPE_OPTIONS.includes(serviceTypeData)
            ? serviceTypeData
            : "",
          other:
            !SERVICE_TYPE_OPTIONS.includes(serviceTypeData) && serviceTypeData
              ? serviceTypeData
              : "",
        }}
        onSubmit={() => {}}
        enableReinitialize
        validationSchema={AddServiceTypeValidationSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Flex width="100%" p="20px" justifyContent="center">
                <Flex flexDirection={"column"}>
                  <Text
                    color="#051226"
                    fontSize={"20px"}
                    fontWeight={600}
                    fontFamily={"SF Pro Display"}
                  >
                    {APPLICATION_CONTENT.QUES_SERVICE_TYPE}
                  </Text>

                  <Flex
                    flexDirection={"column"}
                    gap="10px"
                    my="20px"
                    justifyContent={"space-between"}
                  >
                    <Flex flexDirection={"column"} gap="10px">
                      {SERVICE_TYPE_OPTIONS?.map((service, index) => {
                        return (
                          <Flex
                            gap="16px"
                            alignItems={"center"}
                            p="10px 20px"
                            background={"#EAF2FF"}
                            borderRadius={"4px"}
                            cursor="pointer"
                            onClick={() => {
                              setFieldValue("other", "");
                              setFieldValue("audience", service);
                            }}
                            key={index}
                          >
                            {" "}
                            <RadioButton
                              name="audience"
                              value={service}
                              id={service}
                              variant="secondary"
                            />
                            <Text
                              mb="5px"
                              color="#051226"
                              fontSize={"16px"}
                              fontWeight={600}
                              fontFamily={"Roboto"}
                              mr="100px"
                            >
                              {service}
                            </Text>
                          </Flex>
                        );
                      })}

                      <Box mt="20px" mb="50px">
                        <FormikInput
                          label={VERBIAGE_LABEL.ENTER_OTHER}
                          name="other"
                          variant="outlined"
                          onChange={() => {
                            setFieldValue("audience", "");
                          }}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap="20px"
                py="30px"
                borderTop={"1px solid #D79791"}
                justifyContent={"center"}
              >
                <Flex
                  width={"160px"}
                  height={"60px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor="pointer"
                  borderRadius={"6px"}
                  border={"1px solid #D6BCB9"}
                  fontSize={"18px"}
                  fontFamily={"Roboto"}
                  fontWeight={600}
                  color="#832F27"
                  onClick={() => setStep(0)}
                >
                  {VERBIAGE_LABEL.BACK}
                </Flex>
                <Flex
                  width={"200px"}
                  height={"60px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor={
                    !values?.audience && !values?.other
                      ? "not-allowed"
                      : "pointer"
                  }
                  borderRadius={"6px"}
                  border={"1px solid #BF7F79"}
                  background={"#F2E5E4"}
                  fontSize={"18px"}
                  fontFamily={"Roboto"}
                  fontWeight={600}
                  color="#832F27"
                  onClick={() => {
                    if (values?.audience || values?.other) {
                      dispatch(
                        setApplicationServiceTypeData(
                          values?.audience || values?.other
                        )
                      );
                      setStep(2);
                    }
                  }}
                  opacity={!values?.audience && !values?.other && "0.4"}
                >
                  {VERBIAGE_LABEL.CONTINUE}
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
