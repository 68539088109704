import { useMemo } from "react";
import DefaultFlow from "./Flows/DefaultFlow/DefaultFlow";
import SavannahServiceDogs from "./Flows/SavannahServiceDogs/SavannahServiceDogs";
import MiamiServiceDogs from "./Flows/MiamiServiceDogs/MiamiServiceDogs";
import { VERBIAGE_LABEL } from "utilities/constants";

const Verify = () => {
  const componentTypes = useMemo(() => {
    return {
      [VERBIAGE_LABEL.SERVICE_DOG_DOCUMENTATION_URL]: DefaultFlow,
      [VERBIAGE_LABEL.NYC_SERVICE_DOG_DOCUMENTATION_URL]: DefaultFlow,
      [`test.${VERBIAGE_LABEL.NYC_SERVICE_DOGS_URL}`]: DefaultFlow,
      [`test.${VERBIAGE_LABEL.SAVANNAH_SERVICE_DOGS_URL}`]: SavannahServiceDogs,
      [`test.${VERBIAGE_LABEL.MIAMI_SERVICE_DOGS_URL}`]: MiamiServiceDogs,
      [`test.${VERBIAGE_LABEL.TEXAS_SERVICE_ANIMALS_URL}`]: DefaultFlow,
      [VERBIAGE_LABEL.NYC_SERVICE_DOGS_URL]: DefaultFlow,
      [VERBIAGE_LABEL.SAVANNAH_SERVICE_DOGS_URL]: SavannahServiceDogs,
      [VERBIAGE_LABEL.MIAMI_SERVICE_DOGS_URL]: MiamiServiceDogs,
      [VERBIAGE_LABEL.TEXAS_SERVICE_ANIMALS_URL]: DefaultFlow,
      "localhost:3000": DefaultFlow,
      "localhost:3002": DefaultFlow,
      "localhost:3001": SavannahServiceDogs,
    };
  }, []);

  const RenderComponent = componentTypes[window?.location?.host] || "";

  return <RenderComponent />;
};

export default Verify;
