import * as React from "react";

function Cirecle(props) {
  return (
    <svg
      width={props?.size || "4px"}
      height={props?.size || "4px"}
      viewBox="0 0 4 4"
      fill="none"
      {...props}
    >
      <circle cx={2} cy={2} r={2} fill="#fff" fillOpacity={0.4} />
    </svg>
  );
}

export default Cirecle;
