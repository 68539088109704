import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";
import React from "react";
import LoaderIcon from "./assets/LoaderIcon";
import Tag from "./Tag";
import { Text } from "core-library/Text/Text";
import LinkIcon from "./assets/LinkIcon";
import ShieldIcon from "./assets/ShieldIcon";
import CalendarIcon from "./assets/CalendarIcon";
import HandlerIcon from "./assets/HandlerIcon";
import LocationIcon from "./assets/LocationIcon";
import BellIcon from "./assets/BellIcon";
import CloseIcon from "assets/CloseIcon";
import { useNavigate } from "react-router-dom";
import { VERBIAGE_LABEL, VERIFICATION_CONTENT } from "utilities/constants";

export default function VerififcationDetail({ image, serviceDog, expired }) {
  const navigate = useNavigate();
  return (
    <Flex width="100%" justifyContent={"center"} overflow={"hidden"} my="50px">
      <Box>
        <Flex
          width={"100%"}
          justifyContent={"center"}
          mb="25px"
          cursor="pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <CloseIcon />
        </Flex>
        <Box
          width="375px"
          border="1px solid #711D15"
          borderRadius={"8px"}
          mb="20px"
        >
          {expired && (
            <Flex
              color="#262626"
              background={"#FFD98E"}
              fontFamily={"SF Pro Display"}
              fontSize={"16px"}
              fontWeight={600}
              letterSpacing={"0.64px"}
              p="7px 14px"
              gap="5px"
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"8px 8px 0px 0px"}
            >
              <BellIcon fill="#FFD98E" innerFill="#262626" size="40px" />
              <Text>{VERIFICATION_CONTENT.REGISTRATION_EXPIRED}</Text>
            </Flex>
          )}

          <Box
            mx="15px"
            my="20px"
            p="16px"
            background={"#F2E5E4"}
            borderRadius={"4px"}
          >
            <LoaderIcon />
            <Flex flexDirection={"column"} gap="10px" my="20px">
              <Tag text={VERIFICATION_CONTENT.TAG_ADA} />
              <Flex gap="10px">
                <Tag text={VERIFICATION_CONTENT.TAG_HOUSING_ACT} />
                <Tag text={VERIFICATION_CONTENT.TAG_CARRIER_ACT} />
              </Flex>
              <Tag text={VERIFICATION_CONTENT.TAG_REHABILIATION_ACT} />
            </Flex>

            <Text
              colors="#6F3C37"
              fontSize={"18px"}
              fontWeight={600}
              letterSpacing={"0.36px"}
              fontFamily={"SF Pro Display"}
              color="#6F3C37"
              py="20px"
            >
              {VERIFICATION_CONTENT.SAVANNAH_TEXT}
            </Text>
            <Box
              color="#6F3C37"
              fontFamily={"SF Pro Display"}
              fontSize={"30px"}
              fontWeight={700}
              letterSpacing={"0.6px"}
            >
              <Text>{VERIFICATION_CONTENT.REGISTERED}</Text>
              <Text mt="10px">{VERIFICATION_CONTENT.SERVICE_ANIMAL}</Text>
            </Box>
          </Box>
          <Box p="18px 15px" background={"#932D23"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <LoaderIcon size="24px" fill="#932D23" />
              <Text ml="7px" mr="12px" color="#F6F9FD">
                {VERIFICATION_CONTENT.SERVICE_ANIMAL_ID}
              </Text>
              <Tag
                bgcolor={"#9C4B43"}
                text={serviceDog?.ID || VERIFICATION_CONTENT.DEFAULT_ID}
                width="162px"
                fontSize={"16px"}
                letterSpacing={"1.6px"}
              />
            </Flex>
          </Box>
          <Box p="20px 15px" background={"#EEEEEE"}>
            <Flex
              background={"#FFFFFF"}
              color="#005280"
              fontFamily={"SF Pro Display"}
              fontSize={"16px"}
              fontWeight={600}
              letterSpacing={"0.64px"}
              p="7px 14px"
              borderRadius={"100px"}
              gap="5px"
              alignItems={"center"}
            >
              <Text>{VERIFICATION_CONTENT.SAVANNAH_FEDERAL_LAW_TEXT}</Text>
              <LinkIcon />
            </Flex>
          </Box>

          <Box p="20px 15px" background={"#fff"}>
            <Flex gap="10px">
              <Box>
                <img
                  src={image}
                  alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
                  width={"160px"}
                  height={"140px"}
                  style={{
                    borderRadius: "4px",
                  }}
                />
                <Box fontFamily={"SF Pro Display"} mt="20px">
                  <Text
                    color="#394A64"
                    fontSize={"14px"}
                    fontWeight={600}
                    letterSpacing={"0.56px"}
                  >
                    {VERIFICATION_CONTENT.SERVICE_ANIMAL}
                  </Text>
                  <Text
                    color="#051226"
                    fontSize={"16px"}
                    fontWeight={600}
                    letterSpacing={"0.64px"}
                    textTransform="capitalize"
                  >
                    {serviceDog?.Name || VERBIAGE_LABEL.DEFAULT_DOG_NAME}
                  </Text>
                </Box>
              </Box>
              <Flex flexDirection={"column"} justifyContent={"space-between"}>
                <Flex
                  background={expired ? "#F2F2F2" : "#2A623A"}
                  borderRadius={"2px"}
                  gap="12px"
                  p="10px"
                >
                  <ShieldIcon
                    fill={expired && "#E0E0E0"}
                    innerFill={expired && "#464646"}
                  />
                  <Box
                    color={expired ? "#464646" : "#fff"}
                    fontFamily={"SF Pro Display"}
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      mb="5px"
                      letterSpacing={"0.64px"}
                    >
                      {VERBIAGE_LABEL.REGISTERED}
                    </Text>
                    <Text
                      fontSize={"14px"}
                      fontWeight={500}
                      letterSpacing={"0.28px"}
                    >
                      {VERIFICATION_CONTENT.DEFAULT_DATE}
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  background={expired ? "#626262" : "#F2F2F2"}
                  borderRadius={"2px"}
                  gap="12px"
                  p="10px"
                >
                  {expired ? <BellIcon /> : <CalendarIcon />}
                  <Box
                    color={expired ? "#FFFFFF" : "#464646"}
                    fontFamily={"SF Pro Display"}
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      mb="5px"
                      letterSpacing={"0.64px"}
                    >
                      {VERIFICATION_CONTENT.RENEW_BY}
                    </Text>
                    <Text
                      fontSize={"14px"}
                      fontWeight={500}
                      letterSpacing={"0.28px"}
                    >
                      {VERIFICATION_CONTENT.DEFAULT_DATE}
                    </Text>
                  </Box>
                </Flex>
                <Box fontFamily={"SF Pro Display"}>
                  <Text
                    color="#394A64"
                    fontSize={"14px"}
                    fontWeight={600}
                    letterSpacing={"0.56px"}
                  >
                    {VERBIAGE_LABEL.SERVICE}
                  </Text>
                  <Text
                    color="#051226"
                    fontSize={"16px"}
                    fontWeight={600}
                    letterSpacing={"0.64px"}
                    textTransform="capitalize"
                  >
                    {serviceDog?.Service || VERIFICATION_CONTENT.DEFAULT_SERVICE}
                  </Text>
                </Box>
              </Flex>
            </Flex>
            <Box
              background={"#F3E6E5"}
              borderRadius={"2px"}
              textAlign={"center"}
              fontFamily={"SF Pro Display"}
              fontWeight={600}
              fontSize={"16px"}
              letterSpacing={"0.64px"}
              p="8px 14px"
              color="#791E15"
              mt="20px"
            >
              {VERIFICATION_CONTENT.VIEW_ALL_VACCINATIONS}
            </Box>
          </Box>
          <Box p="18px 15px" background={"#192B46"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Flex>
                <HandlerIcon />
                <Text ml="7px" mr="12px" color="#F6F9FD">
                  {VERIFICATION_CONTENT.HANDLER_ID}
                </Text>
              </Flex>
              <Tag
                bgcolor={"#394A64"}
                text={VERIFICATION_CONTENT.DEFAULT_HANDLER_ID}
                width="162px"
                fontSize={"16px"}
                letterSpacing={"1.6px"}
              />
            </Flex>
          </Box>
          <Box p="20px 15px" background={"#EEEEEE"}>
            <Flex
              background={"#FFFFFF"}
              color="#005280"
              fontFamily={"SF Pro Display"}
              fontSize={"16px"}
              fontWeight={600}
              letterSpacing={"0.64px"}
              p="7px 14px"
              borderRadius={"100px"}
              gap="5px"
              alignItems={"center"}
            >
              <Text>{VERIFICATION_CONTENT.SAVANNAH_SEPARATE_TEXT}</Text>
            </Flex>
          </Box>
          <Box
            p="20px 15px"
            background={"#fff"}
            borderRadius={"0px 0px 8px 8px"}
          >
            <Flex gap="10px">
              <Box>
                <img
                  src={image}
                  alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
                  width={"160px"}
                  height={"140px"}
                  style={{
                    borderRadius: "4px",
                  }}
                />
                <Box fontFamily={"SF Pro Display"} mt="20px">
                  <Text
                    color="#394A64"
                    fontSize={"14px"}
                    fontWeight={600}
                    letterSpacing={"0.56px"}
                  >
                    {VERBIAGE_LABEL.HANDLER}
                  </Text>
                  <Text
                    color="#051226"
                    fontSize={"16px"}
                    fontWeight={600}
                    letterSpacing={"0.64px"}
                  >
                    {VERIFICATION_CONTENT.DEFAULT_HANDLER_NAME}
                  </Text>
                </Box>
              </Box>
              <Flex flexDirection={"column"} justifyContent={"space-between"}>
                <Flex
                  background={"#E7F4EB"}
                  borderRadius={"2px"}
                  gap="12px"
                  p="10px"
                >
                  <ShieldIcon fill="#CEE5D5" innerFill="#38864F" />
                  <Box fontFamily={"SF Pro Display"}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      mb="5px"
                      letterSpacing={"0.64px"}
                      color="#1D5A30"
                    >
                      {VERBIAGE_LABEL.IDENTITY}
                    </Text>
                    <Text
                      color="#2F553B"
                      fontSize={"14px"}
                      fontWeight={500}
                      letterSpacing={"0.28px"}
                    >
                      {VERBIAGE_LABEL.VERIFIED}
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  background={"#F2F2F2"}
                  borderRadius={"2px"}
                  gap="12px"
                  p="10px"
                >
                  <LocationIcon />
                  <Box color="#464646" fontFamily={"SF Pro Display"}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      mb="5px"
                      letterSpacing={"0.64px"}
                    >
                      {VERBIAGE_LABEL.CITY_STATE}
                    </Text>
                    <Text
                      fontSize={"14px"}
                      fontWeight={500}
                      letterSpacing={"0.28px"}
                    >
                      {VERIFICATION_CONTENT.DEFAULT_CITY_STATE}
                    </Text>
                  </Box>
                </Flex>
                <Box fontFamily={"SF Pro Display"}>
                  <Text
                    color="#394A64"
                    fontSize={"14px"}
                    fontWeight={600}
                    letterSpacing={"0.56px"}
                  >
                    {VERBIAGE_LABEL.MOBILE}
                  </Text>
                  <Text
                    color="#051226"
                    fontSize={"16px"}
                    fontWeight={600}
                    letterSpacing={"0.64px"}
                  >
                    {VERIFICATION_CONTENT.DEFAULT_MOBILE}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
