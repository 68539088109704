import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "./CardContent";

const ProfileData = ({ data }) => {
  const labels = Object.keys(data);

  return (
    <Card>
      <CardContent>
        <Grid container>
          {labels?.map((label, index) => {
            if (label !== 'Email') {
              return <>
                <Grid item xs={12} sm={3}>
                  {label}
                </Grid>
                <Grid item xs={12} sm={9} sx={{ color: "#757575" }}>
                  {data[label]}
                </Grid>
                {index < labels.length - 1 && <hr />}
              </>
            }
            return
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileData;
