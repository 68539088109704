import React, {
  FC,
  DetailedHTMLProps,
  InputHTMLAttributes,
  useCallback,
} from "react";
import { Field, useFormikContext } from "formik";
import styled from "styled-components";

import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";
import { Text } from "core-library/Text/Text";
import { Error } from "core-library/Error/Error";
import { Warning } from "core-library/Warning/Warning";
import {
  SpaceProps,
  ColorProps,
  LayoutProps,
  PositionProps,
  TypographyProps,
  BorderProps,
  ShadowProps,
} from "styled-system";

type Variant = "primary" | "secondary" | "error";

interface InputBProps
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    PositionProps,
    TypographyProps,
    BorderProps,
    ShadowProps {
  variant?: Variant;
  maxLength?: any;
  value?: any;
  autoFocus?: boolean;
  defaultValue?: string;
  height?: any;
  width?: any;
  size?: any;
  color?: string;
  placeholder?: string;
  fullSize?: boolean;
  disabled?: boolean;
  bgColorr?: string;
  checkBottomPosition?: string;
  isLoading?: boolean;
  onChange?: (e: any) => void;
}

//@ts-ignore
export interface InputBaseProps
  extends InputBProps,
    DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {}

// @ts-ignore
interface RadioButtonProps extends InputBaseProps {
  id?: string;
  error?: string;
  warning?: string;
  variant?: "primary" | "secondary" | "disabled";
}

export const RadioFieldBase = styled(Box)<RadioButtonProps>`
  position: relative;
  display: grid;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
  label {
    font-size: 1.4rem;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
    border: ${(props) =>
      `2px solid ${
        props?.variant === "disabled"
          ? "#9E9E9E"
          : props.theme.colors[props?.variant ? props?.variant : "primary"]?.[
              "default"
            ]?.border
      }`};
  }
  input[type="radio"]:checked + *::before {
    background: ${(props) => `radial-gradient(
      ${
        props?.variant === "disabled"
          ? "#9E9E9E"
          : props.theme.colors[props?.variant ? props?.variant : "primary"]?.[
              "default"
            ]?.border
      } 0%,
      ${
        props?.variant === "disabled"
          ? "#9E9E9E"
          : props.theme.colors[props?.variant ? props?.variant : "primary"]?.[
              "default"
            ]?.border
      } 40%,
      transparent 50%,
      transparent
    );`};
  }
`;

export const RadioButton: FC<RadioButtonProps> = ({
  id,
  error,
  warning,
  variant: v = "primary",
  onChange,
  ...props
}) => {
  const { handleChange } = useFormikContext();

  const handleInputChange = useCallback(
    (e: any) => {
      e.persist();
      handleChange(e);
      if (onChange) {
        onChange(e);
      }
    },
    [handleChange, onChange]
  );

  return (
    <RadioFieldBase variant={v}>
      <Flex alignItems="center" as="label">
        <Field
          type="radio"
          width="auto"
          {...props}
          onChange={(e: any) => handleInputChange(e)}
        />
        <Text as="span" color="textColor.primary" variant="bodyRegular" />
      </Flex>
      {error && <Error text={error} />}
      {warning && <Warning text={warning} />}
    </RadioFieldBase>
  );
};
