import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { addDog } from "reducers/ProfileReducer";
import { API_URL } from "api/const";
import ServiceDogProfile from "components/ServiceDog/Profile";
import { useLocation } from "react-router-dom";
import { API_URLS } from "utilities/constants/api";
import { VERBIAGE_LABEL, ACCESSOR } from "utilities/constants";

const serviceDogDisplay = (serviceDog) => {
  return {
    [VERBIAGE_LABEL.NAME]: serviceDog?.[ACCESSOR.NAME] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.SERVICE]: serviceDog?.[ACCESSOR.SERVICE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.AGE]: serviceDog?.[ACCESSOR.AGE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.COLOR]: serviceDog?.[ACCESSOR.COLOR] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.DESCRIPTION]: serviceDog?.[ACCESSOR.DESCRIPTION] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.ID]: serviceDog?.[ACCESSOR.SK] || VERBIAGE_LABEL.UNKNOWN,
  };
};

export default Document = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const serviceDog = useSelector(state => state.productsDataState?.productsData?.find(p => p.sk === id))

  const fetchData = async () => {
    let serviceDog;
    try {
      const response = await fetch(`${API_URL}${API_URLS.SERVICE_DOGS}/${id}`);
      serviceDog = await response.json();
    } catch (e) {
      console.error(e.message);
    }
    dispatch(addDog(serviceDog))
    setImage(serviceDog?.imageUrl);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>{serviceDog && <ServiceDogProfile data={serviceDogDisplay(serviceDog)} image={image} />}</>
  );
};

