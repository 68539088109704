import { useSelector } from "react-redux";
import "./Navigation.css";
import { VERBIAGE_LABEL } from "utilities/constants";
import { ROUTE_PATHNAME } from "App";

const titleMap = [
  { url: VERBIAGE_LABEL.NYC_SERVICE_DOGS_URL, title: VERBIAGE_LABEL.NYC_SERVICE_DOGS },
  { url: VERBIAGE_LABEL.SAVANNAH_SERVICE_DOGS_URL, title: VERBIAGE_LABEL.SAVANNAH_SERVICE_DOGS },
  { url: VERBIAGE_LABEL.MIAMI_SERVICE_DOGS_URL, title: VERBIAGE_LABEL.MIAMI_SERVICE_DOGS },
  { url: VERBIAGE_LABEL.SERVICE_DOG_DOCUMENTATION_URL, title: VERBIAGE_LABEL.SERVICE_DOG_DOCUMENTATION },
  { url: VERBIAGE_LABEL.TEXAS_SERVICE_ANIMALS_URL, title: VERBIAGE_LABEL.TEXAS_SERVICE_ANIMALS },
  { url: "localhost", title: VERBIAGE_LABEL.SERVICE_DOG_DOCUMENTATION },
];

export const title = titleMap.find((t) => {
  return (
    t.url.includes(window.location.host) || window.location.host.includes(t.url)
  );
}).title;

const Navigation = () => {
  const loginData = useSelector((state) => state?.loginState?.loggedIn);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <div className="small-wrapper">
            <div>
              <a
                href={loginData?.isLoggedIn ? ROUTE_PATHNAME.PROFILE : ROUTE_PATHNAME.LOGIN}
                className="sign-in btn btn-custom btn-md show-small"
              >
                {loginData?.isLoggedIn ? VERBIAGE_LABEL.PROFILE : VERBIAGE_LABEL.SIGN_IN}
              </a>
            </div>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">{VERBIAGE_LABEL.TOGGLE_NAVIGATION}</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
          </div>
          {/* <a className='navbar-brand page-scroll' href='#page-top'> */}
          {/*   {title} */}
          {/* </a>{' '} */}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                {VERBIAGE_LABEL.FEATURES}
              </a>
            </li>
            {/* <li> */}
            {/*   <a href='#about' className='page-scroll'> */}
            {/*     About */}
            {/*   </a> */}
            {/* </li> */}
            {/* <li> */}
            {/*   <a href='#services' className='page-scroll'> */}
            {/*     Services */}
            {/*   </a> */}
            {/* </li> */}
            {/* <li> */}
            {/*   <a href='#portfolio' className='page-scroll'> */}
            {/*     Gallery */}
            {/*   </a> */}
            {/* </li> */}
            {/* <li> */}
            {/*   <a href='#testimonials' className='page-scroll'> */}
            {/*     Testimonials */}
            {/*   </a> */}
            {/* </li> */}
            {/* <li> */}
            {/*   <a href='#team' className='page-scroll'> */}
            {/*     Team */}
            {/*   </a> */}
            {/* </li> */}
            <li>
              <a href="#contact" className="page-scroll">
              {VERBIAGE_LABEL.CONTACT}
              </a>
            </li>
            <li className="show-large ">
              <a
                href={loginData?.isLoggedIn ? ROUTE_PATHNAME.PROFILE : ROUTE_PATHNAME.LOGIN}
                className="sign-in btn btn-custom btn-md"
              >
                {loginData?.isLoggedIn ? VERBIAGE_LABEL.PROFILE : VERBIAGE_LABEL.SIGN_IN}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
