import * as React from "react";

function ShieldIcon(props) {
  return (
    <svg
      width={props?.size || "44px"}
      height={props?.size || "44px"}
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <rect width={44} height={44} rx={22} fill={props?.fill || "#38864F"} />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={4}
        width={36}
        height={36}
      >
        <path fill="#D9D9D9" d="M4 4h36v36H4z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M20.425 23.598l-2.389-2.388a1.1 1.1 0 00-.783-.342c-.299-.005-.565.109-.797.342-.233.232-.35.5-.35.802 0 .301.117.569.35.801l3.02 3.044a1.3 1.3 0 00.949.406 1.3 1.3 0 00.949-.406l6.216-6.217a1.112 1.112 0 000-1.604 1.103 1.103 0 00-.802-.349c-.302 0-.569.117-.802.35l-5.561 5.561zM22 36.057c-.156 0-.309-.013-.459-.038a2.54 2.54 0 01-.435-.112c-3.164-1.125-5.68-3.12-7.55-5.984-1.87-2.865-2.806-5.956-2.806-9.273v-6.756c0-.567.164-1.08.493-1.536a2.819 2.819 0 011.27-.994l8.538-3.187c.321-.115.637-.173.949-.173.311 0 .628.058.949.173l8.538 3.188c.518.205.94.537 1.27.993.329.457.493.969.493 1.536v6.756c0 3.317-.935 6.408-2.805 9.273-1.87 2.864-4.387 4.859-7.55 5.984a2.54 2.54 0 01-.436.112c-.15.025-.303.038-.459.038z"
          fill={props?.innerFill || "#fff"}
        />
      </g>
    </svg>
  );
}

export default ShieldIcon;
