import * as React from "react";

function ModalCloseIcon(props) {
  return (
    <svg
      width={props?.size || "24px"}
      height={props?.size || "24px"}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.997.997 0 105.7 7.11L10.59 12 5.7 16.89a.997.997 0 001.41 1.41L12 13.41l4.89 4.89a.997.997 0 001.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
        fill="#9F9F9F"
      />
    </svg>
  );
}

export default ModalCloseIcon;
