import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { API_URL } from 'api/const'
import ServiceDogProfile from 'components/ServiceDog/Profile'
import { addApplication, addDog } from "reducers/ProfileReducer";
import { ACCESSOR, VERBIAGE_LABEL } from 'utilities/constants'
import { API_URLS } from 'utilities/constants/api'
import { useDispatch, useSelector } from "react-redux";
import { setGuestEmailData } from 'reducers/LoginReducer'

const serviceDogDisplay = (serviceDog) => {
  return {
    [VERBIAGE_LABEL.NAME]: serviceDog?.[ACCESSOR.NAME] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.SERVICE]: serviceDog?.[ACCESSOR.SERVICE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.AGE]: serviceDog?.[ACCESSOR.AGE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.COLOR]: serviceDog?.[ACCESSOR.COLOR] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.DESCRIPTION]: serviceDog?.[ACCESSOR.DESCRIPTION] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.ID]: serviceDog?.[ACCESSOR.SK] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.BREED]: serviceDog?.[ACCESSOR.BREED] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.EMAIL]: serviceDog?.[ACCESSOR.EMAIL] || VERBIAGE_LABEL.UNKNOWN,
  }
}

const Success = () => {
  const [image, setImage] = useState()
  const [queryParams] = useSearchParams()
  const sessionID = queryParams.get('session_id')
  const applicationId = queryParams.get('application_id')
  const dispatch = useDispatch();
  const serviceDog = useSelector(state => state.productsDataState?.productsData?.find(p => p.applicationId === applicationId))
  const application = useSelector(state => state.productsDataState?.applications?.find(p => p.sk === applicationId))
  const [conversion, setConversion] = useState(false)

  useEffect(() => {
    if (conversion && window.location.hostname === 'servicedogdocumentation.com') {
      window.gtag("event", "purchase", {
        application_id: applicationId,
        stripe_session_id: sessionID,
        items: [
          {
            item_id: applicationId,
          }
        ]
      });
    }
  }, [conversion])

  useEffect(async () => {
    try {
      let serviceDog;
      if (!application) {
        let application;
        try {
          const response = await fetch(
            `${API_URL}${API_URLS.APPLICATIONS}/${applicationId}`,
            { method: 'PUT', body: JSON.stringify({status: 'approved'}) }
          )
          application = await response.json();
          setConversion(true);

          serviceDog = application.serviceDog
          if (serviceDog) {
            delete application.serviceDog; 
          }

          dispatch(addApplication(application))
        } catch (err) {
          throw(err);
        }
      }

      if (serviceDog) {
        dispatch(addDog(serviceDog))
      } else {
        try {
          const response = await fetch(`${API_URL}${API_URLS.APPLICATIONS}/${applicationId}/service-dog`);
          serviceDog = await response.json();
          dispatch(addDog(serviceDog))
        } catch (e) {
          console.error(e.message);
        }
      }
      setImage(serviceDog?.imageUrl);
      dispatch(setGuestEmailData(serviceDog?.email));
    } catch (e) {
      console.error(e.message);
    }
  }, [])

  return (
    <>
      {serviceDog && (
        <ServiceDogProfile data={serviceDogDisplay(serviceDog)} image={image} />
      )}
    </>
  )
}

export default Success;
