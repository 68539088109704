import React from "react";
import { Box } from "core-library/Box/Box";
import LoaderIcon from "./assets/LoaderIcon";
import { Text } from "core-library/Text/Text";
import { Flex } from "core-library/Flex/Flex";
import { VERIFICATION_CONTENT } from "utilities/constants";

export default function LoaderCard({ text }) {
  return (
    <Flex width="100%" justifyContent={"center"} overflow={"hidden"}>
      <Box width="345px" mt="50px">
        <LoaderIcon className="loading" />
        <Text
          fontFamily={"SF Pro Display"}
          fontSize={"18px"}
          fontWeight={600}
          color="#6F3C37"
          mt="30px"
          letterSpacing={"0.72px"}
        >
          {text || VERIFICATION_CONTENT.RETRIEVING_SECURE_ID}
        </Text>
      </Box>
    </Flex>
  );
}
