import * as React from "react";

function LayoutOuter(props) {
  return (
    <svg
      width={props?.size || "342px"}
      height={props?.size || "599px"}
      viewBox="0 0 342 599"
      fill="none"
      {...props}
    >
      <path
        d="M0 11.745C0 5.258 5.373 0 12 0h318c6.627 0 12 5.258 12 11.745v409.131c-.085-.004-.17-.006-.255-.008l-.245-.002c-6.904 0-12.5 5.478-12.5 12.234 0 6.757 5.596 12.235 12.5 12.235.167 0 .334-.003.5-.01v141.93c0 6.487-5.373 11.745-12 11.745H12c-6.627 0-12-5.258-12-11.745v-141.93c6.672-.257 12-5.632 12-12.225s-5.328-11.968-12-12.224V11.746z"
        fill="#0D0D0D"
      ></path>
      {/* {props?.children} */}
    </svg>
  );
}

export default LayoutOuter;
