import * as React from "react";

function NavIcon(props) {
  return (
    <svg
      width={props?.size || "24px"}
      height={props?.size || "24px"}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M5 17a.967.967 0 01-.713-.288A.968.968 0 014 16c0-.283.096-.52.287-.713A.967.967 0 015 15h14c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.968.968 0 0119 17H5zm0-8a.968.968 0 01-.713-.287A.968.968 0 014 8c0-.283.096-.52.287-.713A.968.968 0 015 7h14c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0119 9H5z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default NavIcon;
