import * as React from "react";

function LinkIcon(props) {
  return (
    <svg
      width={props?.size || "24px"}
      height={props?.size || "24px"}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M7 17c-1.383 0-2.563-.488-3.538-1.463C2.487 14.563 2 13.383 2 12s.487-2.563 1.462-3.537C4.438 7.488 5.617 7 7 7h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0110 9H7c-.833 0-1.542.292-2.125.875A2.893 2.893 0 004 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 007 15h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.712A.968.968 0 0110 17H7zm2-4a.967.967 0 01-.713-.287A.968.968 0 018 12c0-.283.096-.52.287-.713A.967.967 0 019 11h6c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0115 13H9zm5 4a.968.968 0 01-.713-.288A.968.968 0 0113 16c0-.283.096-.52.287-.713A.968.968 0 0114 15h3c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0020 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0017 9h-3a.968.968 0 01-.713-.287A.967.967 0 0113 8c0-.283.096-.52.287-.713A.968.968 0 0114 7h3c1.383 0 2.563.487 3.538 1.463C21.512 9.438 22 10.617 22 12s-.488 2.563-1.462 3.537C19.562 16.512 18.383 17 17 17h-3z"
          fill="#005280"
        />
      </g>
    </svg>
  );
}

export default LinkIcon;
