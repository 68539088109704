import withAppProviders from "hocs/withAppProviders";
import Login from "components/Magic/Login";
import SignIn from "components/Magic/SignIn";
import withoutAuthentication from "hocs/withoutAuth";
import { LOGIN_CONTENT } from "utilities/constants";

const page = withAppProviders({
  title: [LOGIN_CONTENT.HEADER],
  offsets: 2,
  route: "/",
})(SignIn);
export default withoutAuthentication(page);
