import React from "react";
import LayoutOuter from "assets/LayoutOuter";
import LayoutInner from "assets/LayoutInner";
import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";

export default function VerificationCard({ children }) {
  return (
    <Flex
      width="100%"
      justifyContent={"center"}
      alignItems={"center"}
      overflow={"hidden"}
      className="topCard"
    >
      <Box position={"relative"} width={"342px"} height={"599px"}>
        <Box position={"absolute"}>
          <LayoutOuter />
        </Box>
        <Box position={"absolute"} left="5px" top="5px">
          <LayoutInner />
        </Box>
        <Box height={"100%"} width="100%">
          {children}
        </Box>
      </Box>
    </Flex>
  );
}
