import { API_URLS } from 'utilities/constants/api'
import { API_URL } from './const'

export const requestSignedUrl = async (file) => {
  const response = await fetch(
    `${API_URL}${API_URLS.IMAGE_UPLOAD_REQUEST}`,
    { method: 'POST', body: JSON.stringify({name: file.name, type: file.type})}
  )
  const data = await response.json()
  return data
}

export const saveFileToS3 = async (s3url, currentFile) => {
  const formData = new FormData();
  formData.append('Content-Type', currentFile.type);
  Object.entries(s3url.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', currentFile); // The file has be the last element
  await fetch(s3url.url, { method: 'POST', body: formData })
}

export const detectDogInImage = async (bucket, key) => {
  const response = await fetch(
    `${API_URL}${API_URLS.DETECT_DOG_IMAGE}?bucket=${bucket}&key=${key}`
  );
  const data = await response.json();
  return data;
};
