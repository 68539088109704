import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pricesData: [],
};

export const HomeReducerSlice = createSlice({
  name: "pricesDataState",
  initialState,
  reducers: {
    setPricesData: (state, action) => {
      state.pricesData = action.payload;
    },
  },
});
export const { setPricesData } = HomeReducerSlice.actions;
export const PricesDataState = (state) => state.pricesDataState;

export default HomeReducerSlice.reducer;
