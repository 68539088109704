import React from "react";
import "./Modal.css";
import { Flex } from "core-library/Flex/Flex";
import { Text } from "core-library/Text/Text";
import { Box } from "core-library/Box/Box";
import { VERBIAGE_LABEL } from "utilities/constants";

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className={"darkBG"}>
        <div className={"centered"}>
          <div className={"modalSection"}>
            <Box flexDirection={"row"}>
              <Text
                color={"#000"}
                mb="12px"
                fontSize={"20px"}
                fontWeight={700}
                fontFamily={"Saira"}
                textAlign={"center"}
              >
                {VERBIAGE_LABEL.WARNING?.toUpperCase()}:
              </Text>
              <Box
                width={"340px"}
                borderRadius={"12px"}
                padding={"5px"}
                height={"160px"}
                background={"#000"}
              >
                <Flex
                  borderRadius={"12px"}
                  border={"1px dashed #FFF"}
                  padding={"10px 32px"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  gap="10px"
                  fontSize={"32px"}
                  fontFamily={"Saira Stencil One"}
                >
                  <Text>{VERBIAGE_LABEL.CERTIFICATION?.toUpperCase()}</Text>
                  <Text>{VERBIAGE_LABEL.EXPIRED?.toUpperCase()}</Text>
                </Flex>
              </Box>
              <Box
                mt="60px"
                color="#000"
                fontSize={"16px"}
                fontWeight={700}
                fontFamily={"Saira"}
                textAlign={"center"}
                cursor="pointer"
                onClick={() => setIsOpen(false)}
              >
                {VERBIAGE_LABEL.CONTINUE?.toUpperCase()}?
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
