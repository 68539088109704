import { useEffect, useState } from "react";
import { toDataURL } from "qrcode";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "components/CardContent";
import ServiceDogDetails from "./ServiceDogDetails";
import Grid from "@mui/material/Grid";
import { Box,  TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { magic } from "../../magic";
import { API_URLS } from "utilities/constants/api";
import { SERVICE_DOG_DETAIL_CONTENT, VERBIAGE_LABEL } from "utilities/constants";

const Offset = () => <div style={{ height: 50 }} />;

const ServiceDogProfile = ({ data, image }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.loginState);

  const [qrCode, setQrCode] = useState();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      await magic.auth.loginWithMagicLink({
        email: userData?.guestEmailAddress,
        redirectURI: new URL(API_URLS.CALLBACK, window.location.origin).href,
      });
      setLoading(false);
      navigate("/");
    } catch {
      setLoading(false);
      // setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    const isVerified = async () => {
      if (data) {
        setQrCode(
          await toDataURL(`${window.location.origin}${API_URLS.VERIFICATION}/${data.ID}`, {
            margin: 0,
            scale: 6,
          })
        );
      }
    };
    isVerified();
  }, []);

  return data ? (
    <>
      {!userData?.loggedIn?.isLoggedIn && (
        <Box
          mb="100px"
          border={"1px solid #00000033"}
          borderRadius={"8px"}
          padding={"12px 16px"}
          component="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={6}>
              {SERVICE_DOG_DETAIL_CONTENT.TEXT}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                className="user-email"
                label={SERVICE_DOG_DETAIL_CONTENT.YOUR_EMAIL_ADDRESS}
                defaultValue={ data?.[VERBIAGE_LABEL.EMAIL] ||userData?.guestEmailAddress}
                margin="normal"
                size="small"
                fullWidth
                id='email-address'
              />
            </Grid>
          </Grid>
          <Grid justifyContent={"center"} container>
            <Box width={"400px"}>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              {VERBIAGE_LABEL.NEXT}
            </LoadingButton>
            </Box>
          </Grid>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ position: "relative", overflow: "visible" }}>
            <Avatar
              src={image}
              sx={{
                width: 100,
                height: 100,
                position: "absolute",
                margin: "-50 auto 0 calc(50% - 50px)",
                border: "2px solid white",
                boxShadow: "0px 2px 6px -3px black",
              }}
            />
            <Offset />
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {data?.[VERBIAGE_LABEL.NAME]}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {data?.[VERBIAGE_LABEL.SERVICE]}
            </Typography>
            <CardContent
              sx={{
                display: "flex",
                "justify-content": "center",
              }}
            >
              <img src={qrCode} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <ServiceDogDetails data={data} />
        </Grid>
      </Grid>
    </>
  ) : (
    <span>{VERBIAGE_LABEL.NO_DATA_FOUND}</span>
  );
};

export default ServiceDogProfile;
