import { Flex } from "core-library/Flex/Flex";
import { Text } from "core-library/Text/Text";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setApplicationImageFileData,
  setApplicationImageFileUrlData,
} from "reducers/ApplicationReducer";
import ImageSelector from "./ImageSelector";
import { APPLICATION_CONTENT, VERBIAGE_LABEL } from "utilities/constants";

export default function Step2({ setStep }) {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);

  const setSelectedFileNames = (e) => {
    dispatch(setApplicationImageFileData(e.target.files[0]));
    dispatch(
      setApplicationImageFileUrlData(URL.createObjectURL(e.target.files[0]))
    );
  };

  const loginData = useSelector((state) => state?.loginState?.loggedIn);
  const imageData = useSelector((state) => state?.applicationState);
  
  return (
    <Flex background={"#fff"} flexDirection={"column"}>
      <Flex
        width={"100%"}
        alignItems={"center"}
        p="20px"
        flexDirection={"column"}
      >
        <Text
          color="#051226"
          fontSize={"20px"}
          fontWeight={600}
          fontFamily={"SF Pro Display"}
        >
          {APPLICATION_CONTENT.QUES_UPLOAD_PICTURE}
        </Text>
        <ImageSelector
          onChange={setSelectedFileNames}
          setDisable={setDisable}
        />
      </Flex>
      <Flex
        gap="20px"
        py="30px"
        borderTop={"1px solid #D79791"}
        justifyContent={"center"}
      >
        <Flex
          width={"160px"}
          height={"60px"}
          justifyContent={"center"}
          alignItems={"center"}
          cursor="pointer"
          borderRadius={"6px"}
          border={"1px solid #D6BCB9"}
          fontSize={"18px"}
          fontFamily={"Roboto"}
          fontWeight={600}
          color="#832F27"
          onClick={() => {
            setStep(1);
          }}
        >
          {VERBIAGE_LABEL.BACK}
        </Flex>
        <Flex
          width={"200px"}
          height={"60px"}
          justifyContent={"center"}
          alignItems={"center"}
          cursor={
            !imageData?.currentFile || disable ? "not-allowed" : "pointer"
          }
          borderRadius={"6px"}
          border={"1px solid #BF7F79"}
          background={"#F2E5E4"}
          fontSize={"18px"}
          fontFamily={"Roboto"}
          fontWeight={600}
          color="#832F27"
          onClick={() => {
            if (imageData?.currentFile && !disable) {
              if (loginData?.email) {
                setStep(4);
              } else {
                setStep(3);
              }
            }
          }}
          opacity={(!imageData?.currentFile || disable) && "0.4"}
        >
          {VERBIAGE_LABEL.CONTINUE}
        </Flex>
      </Flex>
    </Flex>
  );
}
