export const VERBIAGE_LABEL = {
  OWNER_NAME: 'OwnerName',
  LOADING: 'loading',
  FEATURES: 'Features',
  CONTACT: 'Contact',
  PROFILE: 'Profile',
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  APPLY_NOW: 'Apply Now',
  TOGGLE_NAVIGATION: 'Toggle navigation',
  NYC_SERVICE_DOGS: 'NYC Service Dogs',
  SAVANNAH_SERVICE_DOGS: 'Savannah, GA Service Dogs',
  MIAMI_SERVICE_DOGS: 'Miami Service Dogs',
  TEXAS_SERVICE_ANIMALS: 'Texas Service Animals',
  SERVICE_DOG_DOCUMENTATION: 'Service Dog Documentation',
  SERVICE_DOG_CERTIFICATION: 'Service Dog Certification',
  NYC_SERVICE_DOGS_URL: 'nycservicedogcertification.com',
  SAVANNAH_SERVICE_DOGS_URL: 'savannahservicedogs.com',
  SERVICE_DOG_DOCUMENTATION_URL: 'servicedogdocumentation.com',
  MIAMI_SERVICE_DOGS_URL: 'miamiservicedogs.com',
  TEXAS_SERVICE_ANIMALS_URL: 'texasserviceanimals.org',
  NYC_SERVICE_DOG_DOCUMENTATION_URL: 'nycservicedogdocumentation.com',
  ERROR_MESSAGE: 'Sorry but we were unable to send this message. Please try again later.',
  NAME: 'Name',
  EMAIL: 'Email',
  EMAIL_ADDRESS: 'Email Address',
  VIEW_QR_CODE: 'View QR Code',
  MESSAGE: 'Message',
  SENT: "Sent",
  SEND_MESSAGE: 'Send message',
  NEXT: 'Next',
  YEAR: '2023',
  YES: 'Yes',
  NO: 'No',
  BACK: 'Back',
  SAVE: 'Save',
  CONTINUE: 'Continue',
  ENTER_OTHER: "Enter other",
  UPLOAD_PHOTO: 'Upload a photo',
  IMAGE_UPLOAD_SUCCESS: 'Image Uploaded Successfully',
  SERVICE_DOG_IMAGE: 'Service Dog Image',
  PLEASE_TRY_AGAIN: 'Please try again',
  REPLACE_PHOTO: 'Replace photo',
  UNKNOWN: 'Unknown',
  SERVICE: 'Service',
  AGE: 'Age',
  COLOR: 'Color',
  DESCRIPTION: 'Description',
  ID: 'ID',
  BREED: 'Breed',
  NO_DATA_FOUND: 'No Data Found',
  ORDER_CONFIRMATION: 'Order Confirmation',
  FOUND: 'Found',
  EXPIRED: 'EXPIRED',
  DEFAULT_DOG_NAME: 'SUMMER',
  GUIDE_DOG: 'Guide Dog',
  DEFAULT_OWNER_NAME: "John Doe",
  IS: 'is',
  NOT: 'NOT',
  VACCINATED: 'Vaccinated',
  VERIFIED: 'Verified',
  REGISTERED: 'Registered',
  HANDLER: 'Handler',
  IDENTITY: 'Identity',
  CITY_STATE: 'City, State',
  MOBILE: 'Mobile',
  EXCLUSIVE: 'Exclusive',
  AVAILABLE: 'Available',
  DOCUMENTATION: 'Documentation',
  BACK: 'Back',
  SHARE: 'Share',
  LEARN_MORE: 'Learn More',
  WARNING: 'Warning',
  CERTIFICATION: 'Certification',

}

export const HEADER_CONTENT = {
  TEXT: 'Access restaurants, movie theaters, airplanes, grocery stores, and more.',
  VIDEO_URL: "https://mahvideos.s3.amazonaws.com/service+dog+video+for+home+page.mp4"
}

export const FEATURES_CONTENT = {
  HEADER: 'What You Get',
  DIGITAL_ID_LABEL: 'Secure Digital I.D.',
  DIGITAL_ID_TEXT: 'Receive a Digital I.D. of your Service Dog.',
  LIVE_VERIFICATION_LABEL: 'LIVE Verification',
  LIVE_VERIFICATION_TEXT: 'Present your QR Code to any smartphone to verify your Service Dog.',
  MOBILE_DEVICE_LABEL: 'Mobile Device Authentication',
  MOBILE_DEVICE_TEXT: 'Access your Digital I.D. from any smartphone.',
  SATISFACTION_LABEL: 'Satisfaction Guaranteed',
  SATISFACTION_TEXT: 'Access any restaurant, hotel, and movie theater in America, and more!'
}

export const CONTACT_CONTENT = {
  ADDRESS: "212 E. Bolton St, Savannah, GA, 31401",
  PHONE: "+1 754 220 3011",
  EMAIL: "information@digitallicensure.com",
  FACEBOOK: "fb.com",
  TWITTER: "twitter.com",
  YOUTUBE: "youtube.com",
  HEADER: 'Get In Touch',
  TEXT: 'Please fill out the form below to send us an email and we will get back to you as soon as possible.'
}

export const PRODUCTS_CONTENT = {
  MOBILE_QR_CODE: 'Mobile QR Code',
  UNLIMITED_SCANS: 'Unlimited Scans'
}

export const APPLICATION_CONTENT = {
  LOADER_TEXT: 'Preparing your application',
  APPLICATION: 'Application',
  SERVICE_DOG_ID: 'Service Dog Digital ID',
  QUES_SERVICE_DOG: 'Is your dog a Service Dog?',
  QUES_SERVICE_TYPE: 'What service does your dog perform?',
  QUES_UPLOAD_PICTURE: 'Upload a clear photo of your dog for the digital ID.',
  HANDLERS_EMAIL_TEXT: 'Please enter the handler’s email for verification.',
  HANDLERS_EMAIL_LABLEL: "Handler’s Email Address",
  SERVICE_DOG_DETAILS_TEXT: 'Final Step, Enter your Service Dog ID details',
  SERVICE_DOG_NAME: "Service Dog’s Name",
  HANDLER_NAME: "Owner's/Handler's Name",
  SERVICE_DOG_COLOR: "Dog Color",
  SERVICE_DOG_AGE: "Dog Age",
  SERVICE_DOG_BREED: "Dog Breed",
  SERVICE_DOG_DESCRIPTION: "Dog Description",
}

export const SERVICE_TYPE_OPTIONS = [
  "Guidance/Nagivation",
  "Alerting to specific sounds",
  "Pulling wheelchair",
  "Picking up dropped items",
  "Lending balance support",
  "Medical crisis assistance",
]

export const VALIDATIONS = {
  INVALID_EMAIL: 'Invalid Email',
  REQUIRED: 'Required',
}

export const SERVICE_DOG_DETAIL_CONTENT = {
  TEXT: 'Sign in with this email to access your Service Dog I.D. in the future:',
  YOUR_EMAIL_ADDRESS: 'Your Email Address'
}

export const VERIFICATION_CONTENT = {
  DEFAULT_FLOW_ERROR_TEXT: 'We were unable to verify this service dog based on the given id',
  REGISTRATION_EXPIRED: 'Registration Expired',
  RETRIEVING_SECURE_ID: "Retrieving Secure ID",
  TAG_ADA: "ADA | Americans with Disabilities Act",
  TAG_HOUSING_ACT: "Fair Housing Act",
  TAG_CARRIER_ACT: "Air Carrier Act",
  TAG_REHABILIATION_ACT: "Rehabilitation Act of 1973",
  SAVANNAH_TEXT: "US Federal Laws mandates that this service animal and handler have full access to all public places.",
  SERVICE_ANIMAL: 'Service Animal',
  SERVICE_ANIMAL_ID: 'Service Animal ID',
  DEFAULT_ID: "89547035",
  SAVANNAH_FEDERAL_LAW_TEXT: "Full Access Protected By Federal Law",
  DEFAULT_DATE: "Dec. 20, 2022",
  RENEW_BY: 'Renew By',
  DEFAULT_SERVICE: "Seizure Alerting",
  VIEW_ALL_VACCINATIONS: "View All Vaccinations",
  HANDLER_ID: "Handler ID",
  DEFAULT_HANDLER_ID: '65482139',
  SAVANNAH_SEPARATE_TEXT: 'Do Not Separate Animal From Handler',
  DEFAULT_HANDLER_NAME: 'Michael Hars Soong',
  DEFAULT_CITY_STATE: 'Brooklyn, NY',
  DEFAULT_MOBILE: '(123) 123-1234',
  SEARCHING_RECORDS: 'Searching Your Records',
  PERFORMED_AS: 'Performed as ',
  SEIZURE_ALERTING: 'Seizure Alerting',
  MIAMIN_TEXT: 'Vaccinations are verified and up to date',
  DUMMY_TEXT: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a t",
  DOG_ID: 'Dog ID',
  DEFAULT_AGE: '12 years',
  DEFAULT_COLOR: 'Black',
  OWNERS_HANDLERS: 'Owner’s/Handlers',
  OWNER_VERIFICATION: 'Owner Verification',
  RETRIEVING_VERIFICATION: 'Retrieving Verification...',
}

export const LOGIN_CONTENT = {
  HEADER: "Log In / Sign Up",
  SIGN_UP_SIGN_IN: 'Sign Up or Sign in',
  EMAIL_ADDRESS: 'Email Address'
}

export const PROFILE_CONTENT = {
  REGISTER_DOG: 'Register Another Service Dog'
}

export const ACCESSOR = {
  OWNER_NAME: 'ownerName',
  NAME: 'name',
  EMAIL: 'email',
  SERVICE: 'service',
  AGE: 'age',
  COLOR: 'color',
  DESCRIPTION: 'description',
  SK: 'sk',
  BREED: 'breed',
}
