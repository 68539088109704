import { HEADER_CONTENT, VERBIAGE_LABEL } from "utilities/constants";
import "./Header.css";
const Header = (props) => {
  return (
    <header id="header">
      <div className="banner-section intro">
        <div className="col-md-8 col-md-offset-2 intro-text">
          <h1>
            {VERBIAGE_LABEL.SERVICE_DOG_CERTIFICATION || VERBIAGE_LABEL.LOADING}
            <span></span>
          </h1>
          <p>{HEADER_CONTENT.TEXT || VERBIAGE_LABEL.LOADING}</p>
          <a href="#prices" className="btn btn-custom btn-lg page-scroll">
            {VERBIAGE_LABEL.APPLY_NOW}
          </a>{" "}
        </div>
      </div>
      <video
        src={HEADER_CONTENT.VIDEO_URL}
        muted
        playsInline
        autoMuted
        autoPlay
        loop
      ></video>
    </header>
  );
};

export default Header;
