import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { magic } from "magic";
import Loading from "components/Magic/Loading";
import { useSelector } from "react-redux";

const withoutAuthentication = (WrappedComponent) => (props) => {
  const loginData = useSelector((state) => state?.loginState?.loggedIn);
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // On mount, we check if a user is logged in.
    // If so, we'll retrieve the authenticated user's profile.
    if (loginData?.isLoggedIn) {
      navigate("/profile");
    } else {
      setDisplay(true);
    }
  }, [loginData?.isLoggedIn]);

  return display ? <WrappedComponent {...props} /> : <Loading />;
};

export default withoutAuthentication;
