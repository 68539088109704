import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";
import { Text } from "core-library/Text/Text";
import React from "react";
import { useNavigate } from "react-router";
import { APPLICATION_CONTENT, VERBIAGE_LABEL } from "utilities/constants";

export default function Step0({ setStep }) {
  const navigate = useNavigate();
  return (
    <Flex width="100%" justifyContent="center" p="20px">
      <Box>
        <Text
          color="#051226"
          fontSize={"20px"}
          fontWeight={600}
          fontFamily={"SF Pro Display"}
        >
          {APPLICATION_CONTENT.QUES_SERVICE_DOG}
        </Text>
        <Flex gap="20px" mt="50px">
          <Flex
            width={"160px"}
            height={"60px"}
            justifyContent={"center"}
            alignItems={"center"}
            cursor="pointer"
            borderRadius={"6px"}
            border={"1px solid #BF7F79"}
            background={"#F2E5E4"}
            fontSize={"18px"}
            fontFamily={"Roboto"}
            fontWeight={600}
            color="#832F27"
            onClick={() => setStep(1)}
          >
            {VERBIAGE_LABEL.YES}
          </Flex>
          <Flex
            width={"160px"}
            height={"60px"}
            justifyContent={"center"}
            alignItems={"center"}
            cursor="pointer"
            borderRadius={"6px"}
            border={"1px solid #D6BCB9"}
            fontSize={"18px"}
            fontFamily={"Roboto"}
            fontWeight={600}
            color="#832F27"
            onClick={() => navigate("/")}
          >
            {VERBIAGE_LABEL.NO}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
