import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { API_URL } from "api/const";
import ServiceDogDetails from "components/ServiceDog/ServiceDogDetails";
import Card from "@mui/material/Card";
import CardContent from "components/CardContent";
import Grid from "@mui/material/Grid";
import VerificationDetail from "./VerificationDetail";
import LoadingCard from "./LoadingCard";
import "./Verification.css";
import { useNavigate } from "react-router-dom";
import { VERBIAGE_LABEL, VERIFICATION_CONTENT, ACCESSOR } from "utilities/constants";
import { API_URLS } from "utilities/constants/api";

const serviceDogDisplay = (serviceDog) => {
  return {
    [VERBIAGE_LABEL.OWNER_NAME]: serviceDog?.[ACCESSOR.OWNER_NAME] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.NAME]: serviceDog?.[ACCESSOR.NAME] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.SERVICE]: serviceDog?.[ACCESSOR.SERVICE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.AGE]: serviceDog?.[ACCESSOR.AGE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.COLOR]: serviceDog?.[ACCESSOR.COLOR] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.DESCRIPTION]: serviceDog?.[ACCESSOR.DESCRIPTION] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.ID]: serviceDog?.[ACCESSOR.SK] || VERBIAGE_LABEL.UNKNOWN,
  };
};

export default function DefaultFlow() {
  const navigate = useNavigate();
  const [serviceDog, setServiceDog] = useState();
  const [image, setImage] = useState();
  const [expired, setExpired] = useState(false);
  const [snackBarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { open, vertical, horizontal } = snackBarState;
  const [showCard2, setShowCard2] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [dataFetch, setDataFetch] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCard2(true);
    }, 3000);
    if (showCard2) {
      setTimeout(() => {
        setLoadingData(false);
      }, 3000);
    }
  }, [showCard2]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}${API_URLS.VERIFY}/${id}`);
        const serviceDog = await response.json();
        setServiceDog(serviceDogDisplay(serviceDog));
        setImage(serviceDog?.imageUrl);
        setDataFetch(true);
      } catch (e) {
        setDataFetch(false);
        setTimeout(() => {
          setSnackbarState({
            ...snackBarState,
            open: true,
          });
        }, 2000);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() => navigate("/")}
      >
        <Alert
          onClose={() => navigate("/")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {VERIFICATION_CONTENT.DEFAULT_FLOW_ERROR_TEXT}:{" "}
          {location.pathname.split("/")[2]}
        </Alert>
      </Snackbar>

      {!showCard2 ? (
        <div className="retrieveCard">
          <LoadingCard
            textContent={VERIFICATION_CONTENT.RETRIEVING_VERIFICATION}
            type={"blackcard"}
          />
        </div>
      ) : dataFetch ? (
        <>
          {loadingData ? (
            <div className="retrieveCard2">
              <LoadingCard textContent={VERBIAGE_LABEL.FOUND} />
            </div>
          ) : serviceDog ? (
            <VerificationDetail
              image={image}
              serviceDog={serviceDog}
              expired={expired}
            />
          ) : (
            <div style={{ margin: "50px 25px 0px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card sx={{ position: "relative" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        "justify-content": "center",
                      }}
                    >
                      <img
                        src={image}
                        style={{ maxWidth: "100%" }}
                        alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                  <ServiceDogDetails data={serviceDog} />
                </Grid>
              </Grid>
            </div>
          )}
        </>
      ) : (
        <div className="retrieveCard2">
          <LoadingCard textContent={""} />
        </div>
      )}
    </>
  );
}
