import LoaderIcon from "pages/Home/components/Loader/LoaderIcon";
import React from "react";
import "./Loading.css";

export default function Loading() {
  return (
    <div className="ajax-loader">
      <LoaderIcon />
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
      <div className="paw">
        <svg className="icon">
          <use xlinkHref="#paw" />
        </svg>
      </div>
    </div>
  );
}
