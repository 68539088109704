const colors = {
  primary: {
    default: {
      border: "#DD015B",
      bg: "#DD015B",
      iconColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    hover: {
      border: "#DD015BB2",
      bg: "#DD015BB2",
      iconColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    click: {
      border: "#B6004A",
      bg: "#B6004A",
      iconColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    disabled: {
      border: "#EFEFEF",
      bg: "#EFEFEF",
      iconColor: "",
      color: "#8A8A8A",
    },
  },
  secondary: {
    default: {
      border: "#051226",
      bg: "#FFF",
      iconColor: "#0094E3",
      color: "#0094E3",
    },
    hover: {
      border: "#0094E3",
      bg: "#0094E3",
      iconColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    click: {
      border: "#0043C7",
      bg: "#0043C7",
      iconColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    disabled: {
      border: "#EFEFEF",
      bg: "#EFEFEF",
      iconColor: "#8A8A8A",
      color: "#8A8A8A",
    },
  },
  salesforce: {
    default: {
      border: "#FFFFFF",
      bg: "#FFFFFF",
      iconColor: "#0076d2",
      color: "#050505",
    },
    hover: {
      border: "#0076d2",
      bg: "#0076d2",
      iconColor: "#fff",
      color: "#fff",
    },
    click: {
      border: "#0076d2",
      bg: "#0076d2",
      iconColor: "#fff",
      color: "#fff",
    },
    disabled: {
      border: "#032D60",
      bg: "#032D60",
      iconColor: "#050505",
      color: "#050505",
    },
  },
  google: {
    default: {
      border: "#FFFFFF",
      bg: "#FFFFFF",
      iconColor: "#0076d2",
      color: "#050505",
    },
    hover: {
      border: "#FFFFFF",
      bg: "#FFFFFF",
      iconColor: "#0076d2",
      color: "#050505",
    },
    click: {
      border: "#FFFFFF",
      bg: "#FFFFFF",
      iconColor: "#0076d2",
      color: "#050505",
    },
    border: "#FFFFFF",
    bg: "#FFFFFF",
    iconColor: "#0076d2",
    color: "#050505",
  },
  profileSendTreat: {
    default: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    hover: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    click: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    disabled: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
  },
  copy: {
    default: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    hover: {
      border: "none",
      bg: "#DD015B",
      iconColor: "#FFFFFF",
      color: "#FFFFFF",
    },
    click: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    disabled: {
      border: "none",
      bg: "rgba(221, 1, 91, 0.05)",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
  },
  undo: {
    default: {
      border: "none",
      bg: "transparent",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    hover: {
      border: "none",
      bg: "transparent",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    click: {
      border: "none",
      bg: "transparent",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    disabled: {
      border: "none",
      bg: "transparent",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
  },
  reviewConfirm: {
    default: {
      border: "none",
      bg: "transparent",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    hover: {
      border: "none",
      bg: "#fff",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    click: {
      border: "none",
      bg: "#fff",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
    disabled: {
      border: "none",
      bg: "#fff",
      iconColor: "#DD015B",
      color: "#DD015B",
    },
  },
  confirm: {
    default: {
      border: "none",
      bg: "transparent",
      iconColor: "black",
      color: "black",
    },
    hover: {
      border: "none",
      bg: "transparent",
      iconColor: "black",
      color: "black",
    },
    click: {
      border: "none",
      bg: "transparent",
      iconColor: "black",
      color: "black",
    },
    disabled: {
      border: "none",
      bg: "transparent",
      iconColor: "black",
      color: "black",
    },
  },
  basic: {
    white: "#FFFFFF",
    grey: "#CCCCCC",
    greyLight: "#C2C2C2",
    greyMedium: "#595959",
    greyDark: "#4F5B67",
    black: "#000000",
  },
  eva: {
    evaRed: "#DD015B",
    evaPurple: "#16124C",
    evaYellow: "#F9E9B1",
    evaGreen: "#1B9788",
  },
  gradient: {
    purpleGradient: { start: "#592582", end: "#1D1762" },
  },
  basicColors: {
    basicGreen: "#24B300",
    basicOrange: "#E25F00",
    basicBlue: "#0094E3",
    basicRed: "#E40606",
  },
  textColor: {
    primary: "#050505",
    secondary: "#57585C",
    deactivate: "#9F9F9F",
    disable: "#DFDFDF",
  },
  error: "#EE0004",
  labelColor: "#1A1A1A",
  alert: {
    informative: { icon: "#1FA1FF", bg: "#EBF9FF" },
    warning: { icon: "#FCA004", bg: "#FFF6EB" },
    success: { icon: "#24B300", bg: "#EEFFF3" },
    error: { icon: "#FD1B1B", bg: "#FFF6F8" },
  },
};

const borders = {
  border1: "2px solid rgba(0, 0, 0, 0.2)",
  border2: "1px solid rgba(0, 0, 0, 0.1)",
  border3: "0.5px solid rgba(0, 0, 0, 0.2)",
};

const radii = {
  borderRadius1: 8,
};

const shadows = {
  shadow1: "0px 1px 4px rgba(0, 0, 0, 0.2)",
  shadow2: "0px 1px 2px rgba(100, 116, 139, 0.12)",
  shadow3: "0px 1px 2px rgba(100, 116, 139, 0.12)",
};

export const fontSets = [
  {
    name: "heading1",
    fontWeight: 400,
    fontSize: 40,
    letterSpacing: 0.01,
  },
  {
    name: "heading2",
    fontWeight: 400,
    fontSize: 32,
    letterSpacing: 0.01,
  },
  {
    name: "pageHeadingRegular",
    fontWeight: 400,
    fontSize: 24,
  },
  {
    name: "pageHeadingMedium",
    fontWeight: 500,
    fontSize: 24,
  },
  {
    name: "title",
    fontWeight: 500,
    fontSize: 20,
  },
  {
    name: "subTitle",
    fontWeight: 500,
    fontSize: 16,
  },
  {
    name: "bodyRegular",
    fontWeight: 400,
    fontSize: 14,
  },
  {
    name: "bodyMedium",
    fontWeight: 500,
    fontSize: 14,
  },
  {
    name: "labelRegular",
    fontWeight: 400,
    fontSize: 24,
  },
  {
    name: "labelMedium",
    fontWeight: 500,
    fontSize: 24,
  },
  {
    name: "ctaLargeMedium",
    fontWeight: 500,
    fontSize: 16,
  },
  {
    name: "ctaSmallExtraSmall",
    fontWeight: 500,
    fontSize: 14,
  },
  {
    name: "imputLabel",
    fontWeight: 400,
    fontSize: 14,
  },
  {
    name: "imputText",
    fontWeight: 400,
    fontSize: 16,
  },
  {
    name: "inputHint",
    fontWeight: 400,
    fontSize: 12,
  },
];

export const fontMaps = fontSets.reduce(
  (initial, { name, fontWeight, fontSize, letterSpacing }) => {
    const fm: any = initial;
    fm.fontSizes[name] = fontSize || "";
    fm.fontWeights[name] = fontWeight || "";
    fm.letterSpacings[name] = letterSpacing || "";
    return fm;
  },
  {
    fontSizes: {},
    fontWeights: {},
    letterSpacings: {},
    lineHeights: { lineHeight1: 140 },
  }
);

export const sizeSets = [
  { name: "l", width: 115, height: 46 },
  { name: "m", width: 115, height: 42 },
  { name: "s", width: 92, height: 36 },
  { name: "xs", width: 84, height: 32 },
];

export const sizes = sizeSets.reduce(
  (initial, { name, width, height }) => {
    const fm: any = initial;
    fm.widths[name] = width || "";
    fm.heights[name] = height || "";
    return fm;
  },
  { widths: {}, heights: {} }
);

export const theme = {
  colors,
  sizes,
  fontSets,
  borders,
  shadows,
  radii,
  ...fontMaps,
};
