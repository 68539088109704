import React from "react";
import { useEffect, useState } from "react";
import { API_URL, STRIPE_SUFFIX } from "api/const";
import Add from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceDogCard from "components/ServiceDogCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Grid";
import "./UploadImage.css";
import { useDispatch, useSelector } from "react-redux";
import { setProductsData } from "reducers/ProfileReducer";
import { API_URLS } from "utilities/constants/api";
import { PROFILE_CONTENT, VERBIAGE_LABEL } from "utilities/constants";
import { ROUTE_PATHNAME } from "App";

const UserProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loginData = useSelector((state) => state?.loginState?.loggedIn);
  const productsData = useSelector(
    (state) => state?.productsDataState?.productsData
  );
  const [serviceDogs, setServiceDogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserProfile = async () => {
      if (loginData?.email && !location?.pathname?.includes(VERBIAGE_LABEL.BACK)) {
        const response = await fetch(
          `${API_URL}${API_URLS.USER_PROFILES}?email=${loginData?.email}`
        );
        const data = await response.json();
        setServiceDogs(data);
        dispatch(setProductsData(data));
      }
    };
    loadUserProfile();
  }, [dispatch, loginData?.email]);

  useEffect(() => {
    if (location?.pathname?.includes(VERBIAGE_LABEL.BACK)) {
      setServiceDogs(productsData);
    }
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => navigate(ROUTE_PATHNAME.APPLY)}
      >
        <Box
          className="input-image"
          sx={{
            height: window.innerWidth < 600 ? "140px" : "100%",
          }}
        >
          <Add />
          <div className="ml-2 mt-2">{PROFILE_CONTENT.REGISTER_DOG}</div>
          {/* <div className="ml-2 mt-2 subText"> */}
          {/*   {window.innerWidth > "480" ? "Click to upload a photo" : "Tap to upload or take a photo"} */}
          {/* </div> */}
        </Box>
      </Grid>
      {serviceDogs &&
        serviceDogs?.map((serviceDog, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <ServiceDogCard dog={serviceDog} />
          </Grid>
        ))}
    </Grid>
  );
};

export default UserProfile;
