import { API_URL } from "api/const";
import { API_URLS } from "./constants/api";

const goToCheckout = async (data, priceDefault, state) => {
  // this service endpoint creates the stripe checkout session object, e.g. `stripe.checkout.sessions.create`
  const response = await fetch(`${API_URL}${API_URLS.APPLICATIONS}`, {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify({
      priceId: state?.priceId || priceDefault?.priceId,
      amountInCents: state?.priceInCents || priceDefault?.priceInCents,
      productDescription:
        state?.productDescription || priceDefault?.productDescription,
      data,
    }),
  });

  if (response.ok) {
    const { url } = await response.json();
    // if (response.redirected) {
    // window.location.href = response.url + STRIPE_SUFFIX;
    // window.location.href = response.url;
    window.location.href = url;
    localStorage.setItem('url', url)
    return { url };
    // }
  } else {
    throw(await response.text())
  }
};

export default goToCheckout;
