import { Box } from "core-library/Box/Box";
import { Flex } from "core-library/Flex/Flex";
import { Text } from "core-library/Text/Text";
import React, { useEffect } from "react";
import NavIcon from "./assets/NavIcon";
import IdIcon from "./assets/IdIcon";
import { useState, useMemo } from "react";
import Step0 from "./components/Step0";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import { useDispatch } from "react-redux";
import {
  setApplicationDogDetailsData,
  setApplicationHandlerEmailData,
  setApplicationImageFileData,
  setApplicationImageFileUrlData,
  setApplicationImageLoadData,
  setApplicationServiceTypeData,
  setApplicationSignedUrl,
} from "reducers/ApplicationReducer";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import { title } from "pages/Home/components/Navigation";
import { setGuestEmailData } from "reducers/LoginReducer";
import { APPLICATION_CONTENT } from "utilities/constants";

export default function ApplicationFlow() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const componentTypes = useMemo(() => {
    return {
      0: Step0,
      1: Step1,
      2: Step2,
      3: Step3,
      4: Step4,
    };
  }, []);

  const RenderComponent = componentTypes[step] || Step0;

  useEffect(() => {
    dispatch(setApplicationServiceTypeData(""));
    dispatch(setApplicationImageFileData(""));
    dispatch(setApplicationImageFileUrlData(""));
    dispatch(setApplicationImageLoadData(""));
    dispatch(setApplicationHandlerEmailData(""));
    dispatch(setGuestEmailData(""));
    dispatch(setApplicationDogDetailsData({}));
    dispatch(setApplicationSignedUrl(""));
  }, []);

  return (
    <Box background={"#fff"} height={"100%"}>
      <Flex
        padding="15px"
        justifyContent={"space-between"}
        background={"#192B46"}
        alignItems={"center"}
      >
        <Text
          fontFamily={"SF Pro Display"}
          fontSize={"16px"}
          fontWeight={600}
          color="#fff"
        >
          {title}
        </Text>
        <NavIcon />
      </Flex>
      <Flex
        padding="20px 15px"
        justifyContent={"space-between"}
        background={"#F4F9FF"}
        borderBottom={"1px solid #C7D6EC"}
        alignItems={"center"}
      >
        <Text
          fontFamily={"SF Pro Display"}
          fontSize={"16px"}
          fontWeight={600}
          color="#00304B"
        >
          {APPLICATION_CONTENT.APPLICATION}:
        </Text>
        <Flex
          gap="10px"
          alignItems={"center"}
          padding="10px 15px"
          borderRadius={"100px"}
          background={"#fff"}
        >
          <IdIcon />
          <Text
            fontFamily={"SF Pro Display"}
            fontSize={"16px"}
            fontWeight={600}
            color="#00304B"
          >
            {APPLICATION_CONTENT.SERVICE_DOG_ID}
          </Text>
        </Flex>
      </Flex>
      <RenderComponent setStep={setStep} />
    </Box>
  );
}
