export const API_URLS = {
  FETCH_PRICES: '/prices',
  IMAGE_UPLOAD_REQUEST: '/image-upload-request',
  DETECT_DOG_IMAGE: '/detect-dog-in-image',
  ORDERS: '/orders',
  CALLBACK: '/callback',
  VERIFICATION: '/verification',
  SERVICE_DOGS: '/service-dogs',
  VERIFY: '/verify',
  USER_PROFILES: '/userProfiles',
  APPLICATIONS: '/applications'
}
