import styled, { css as sCss } from "styled-components";
import systemCss, { SystemStyleObject } from "@styled-system/css";

import {
  color,
  layout,
  grid,
  space,
  PositionProps,
  position,
  background,
  ColorProps,
  border,
  shadow,
  LayoutProps,
  SpaceProps,
  GridProps,
  BackgroundProps,
  BorderProps,
  typography,
  TypographyProps,
  flexbox,
  FlexboxProps,
  ShadowProps,
  system,
  variant,
} from "styled-system";
import { fontSets, sizeSets } from "core-library/theme/theme";

const whiteSpace = system({
  whiteSpace: {
    property: "whiteSpace",
  },
  wordBreak: {
    property: "wordBreak",
  },
});

const variants = fontSets.reduce((fontMap, { name }) => {
  const fm: any = fontMap;
  fm[name] = {
    fontSize: name,
    letterSpacing: name,
    fontWeight: name,
  };
  return fm;
}, {});

const fontNames = fontSets.map((f) => f.name);
const sizeNames = sizeSets.map((s) => s.name);

type Variant = (typeof fontNames)[number];
export type Size = (typeof sizeNames)[number];

export interface BoxProps
  extends ColorProps,
    LayoutProps,
    SpaceProps,
    GridProps,
    PositionProps,
    BorderProps,
    BackgroundProps,
    TypographyProps,
    FlexboxProps,
    ShadowProps {
  ref?: any;
  cursor?: string;
  borderRadius?: string | number;
  firstLetterCapital?: boolean;
  textDecoration?: string;
  textWrap?:
    | "wrap"
    | "nowrap"
    | "balance"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset";
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "initial"
    | "inherit";
  whiteSpace?:
    | "normal"
    | "nowrap"
    | "pre"
    | "pre-line"
    | "pre-wrap"
    | "initial"
    | "inherit";
  wordBreak?:
    | "normal"
    | "break-all"
    | "keep-all"
    | "break-word"
    | "initial"
    | "inherit";
  truncate?: boolean;
  css?: SystemStyleObject;
  variant?: Variant;
  size?: Size;
  disabled?: boolean;
}

export const Box = styled.div<BoxProps>`
  position: relative;
  ${({ size }) =>
    size &&
    sCss`
      width: ${(props) => props?.theme?.sizes?.widths[size]}px;
      height: ${(props) => props?.theme?.sizes?.heights[size]}px;
    `}
  ${variant({
    variants,
  })}
  ${space}
  ${color}
  ${layout}
  ${background}
  ${position}
  ${grid}
  ${border}
  ${flexbox}
  ${shadow}
  ${typography}
  ${whiteSpace}
  ${({ cursor }) =>
    cursor &&
    sCss`
      cursor: ${cursor};
    `}
  ${({ truncate }) =>
    truncate &&
    sCss`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
  ${({ textTransform }) =>
    textTransform &&
    sCss`
      text-transform: ${textTransform};
    `}
    ${({ textWrap }) =>
    textWrap &&
    sCss`
      text-wrap: ${textWrap};
    `}
  ${({ textDecoration }) =>
    textDecoration &&
    sCss`
      text-decoration: ${textDecoration};
    `}
  ${({ firstLetterCapital }) =>
    firstLetterCapital &&
    sCss`
      &::first-letter {
        text-transform: uppercase;
      }
    `}
${({ css }) => css && systemCss(css)}
`;
