import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageLoading: false,
  currentFile: "",
  currentFileUrl: "",
};

export const ImageUploadReducerSlice = createSlice({
  name: "imageUploadState",
  initialState,
  reducers: {
    setImageLoadData: (state, action) => {
      state.imageLoading = action.payload;
    },
    setImageFileData: (state, action) => {
      state.currentFile = action.payload;
    },
    setImageFileUrlData: (state, action) => {
      state.currentFileUrl = action.payload;
    },
  },
});
export const { setImageLoadData, setImageFileData, setImageFileUrlData } =
  ImageUploadReducerSlice.actions;
export const ImageUploadState = (state) => state.imageUploadState;

export default ImageUploadReducerSlice.reducer;
