import * as React from "react";
import "../SavannahServiceDogs.css";

function LoaderIcon(props, className) {
  return (
    <svg
      width={props?.size || "50px"}
      height={props?.size || "50px"}
      className={className || ""}
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <circle cx={25} cy={25} r={25} fill={props?.fill || "#711D15"} />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={6}
        y={6}
        width={38}
        height={38}
      >
        <path fill="#D9D9D9" d="M6.25 6.25h37.5v37.5H6.25z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M24.492 39.063c-.443 0-.827-.163-1.152-.489a1.576 1.576 0 01-.488-1.152V28.71l-6.72 3.906a1.73 1.73 0 01-1.25.156 1.425 1.425 0 01-.976-.742l-.508-.898a1.73 1.73 0 01-.156-1.25c.104-.443.352-.768.742-.977L20.704 25l-6.759-3.906c-.39-.209-.644-.54-.761-.996-.118-.456-.059-.866.175-1.23l.586-.9c.235-.364.567-.611.996-.741.43-.13.84-.079 1.23.156l6.68 3.945v-8.75c0-.443.163-.827.489-1.152.325-.326.71-.489 1.152-.489h1.016c.443 0 .827.163 1.152.489.326.325.488.71.488 1.152v8.75l6.68-3.945c.39-.235.8-.287 1.23-.156.43.13.762.377.997.742l.586.898c.234.365.293.775.175 1.23-.117.456-.37.788-.761.997L29.297 25l6.719 3.906c.39.209.644.54.761.996.117.456.059.866-.175 1.23l-.547.9a1.745 1.745 0 01-.996.741c-.43.13-.84.079-1.23-.156l-6.68-3.906v8.71c0 .444-.163.828-.489 1.153-.325.326-.71.489-1.152.489h-1.016z"
          fill={props?.fillColor || "#F6F9FD"}
        />
      </g>
    </svg>
  );
}

export default LoaderIcon;
