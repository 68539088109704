import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { magic } from "magic";
import Loading from "components/Magic/Loading";
import { useDispatch } from "react-redux";
import { setLoginData } from "reducers/LoginReducer";

const withAuthentication = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const [userMetadata, setUserMetadata] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // On mount, we check if a user is logged in.
    // If so, we'll retrieve the authenticated user's profile.
    magic.user.isLoggedIn().then((magicIsLoggedIn) => {
      if (magicIsLoggedIn) {
        magic.user.getMetadata().then((response) => {
          setUserMetadata(response);
          if (response) {
            localStorage?.setItem("isLoggedIn", true);
            dispatch(
              setLoginData({
                email: response?.email.toLocaleLowerCase(),
                isLoggedIn: true,
              })
            );
          }
        });
      } else {
        // If no user is logged in, redirect to `/login`
        localStorage?.setItem("isLoggedIn", false);
        dispatch(
          setLoginData({
            email: "",
            isLoggedIn: false,
          })
        );
        navigate("/login");
      }
    });
  }, []);
  /**
   * Perform logout action via Magic.
   */

  return userMetadata ? <WrappedComponent {...props} /> : <Loading />;
};

export default withAuthentication;
