import { FEATURES_CONTENT } from "utilities/constants"
import "./Features.css"

const Features = () => {
  const icons = {
    "thinshield": 'M237.3 22.6c12-5.1 25.5-5.1 37.5 0l176.7 75c16.6 7.1 28.6 23.2 28.5 42.4c-.5 96.6-40.3 270.2-204.5 348.8c-12.3 5.9-26.7 5.9-39 0C72.3 410.1 32.5 236.5 32 139.9c-.1-19.1 11.9-35.3 28.5-42.4l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zM208 128H176c-26.5 0-48 21.5-48 48v40c0 22.3 15.2 41.1 35.8 46.4l-19 38c-2 4-.4 8.8 3.6 10.7s8.8 .4 10.7-3.6l21.9-43.8c24.1-2.5 42.9-22.9 42.9-47.7V144h68.2c9.1 0 17.4 5.1 21.5 13.3l7.2 14.3c1.4 2.7 4.1 4.4 7.2 4.4h64c4.4 0 8 3.6 8 8v24c0 39.8-32.2 72-72 72H280c-4.4 0-8 3.6-8 8v88c0 4.4 3.6 8 8 8s8-3.6 8-8V296h40c48.6 0 88-39.4 88-88V184c0-13.3-10.7-24-24-24H332.9l-4.9-9.9c-6.8-13.6-20.6-22.1-35.8-22.1H224h-8-8zm0 88c0 17.6-14.2 31.9-31.8 32l-.3 0c-17.6-.1-31.9-14.4-31.9-32V176c0-17.7 14.3-32 32-32h16 16v16 56zm64-8a16 16 0 1 0 0-32 16 16 0 1 0 0 32z',
    "lightshield": 'M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zM200 128H178.7c-32.4 0-58.7 26.3-58.7 58.7V216c0 22.6 13.4 42.1 32.7 50.9l-15 29.9c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2L186.4 271c26-4.9 45.6-27.7 45.6-55V160h60.2c6.1 0 11.6 3.4 14.3 8.8l7.2 14.3c2.7 5.4 8.2 8.8 14.3 8.8h56v16c0 35.3-28.7 64-64 64H272c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16V304h32c53 0 96-43 96-96V192c0-17.7-14.3-32-32-32H337.9l-2.7-5.5C327 138.3 310.4 128 292.2 128H232 216 200zM175.7 240c-13.1-.1-23.7-10.8-23.7-24V186.7c0-10.9 6.6-20.3 16-24.4c3.3-1.4 6.9-2.2 10.7-2.2H200v32 24c0 13.2-10.6 23.9-23.7 24l-.5 0zM272 208a16 16 0 1 0 0-32 16 16 0 1 0 0 32z',
    "regularshield": 'M73 127L256 49.4 439 127c5.9 2.5 9.1 7.8 9 12.8c-.4 91.4-38.4 249.3-186.3 320.1c-3.6 1.7-7.8 1.7-11.3 0C102.4 389 64.5 231.2 64 139.7c0-5 3.1-10.2 9-12.8zM457.7 82.8L269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2zM160.9 270.2l-15 30c-1.2 2.5-1.9 5.2-1.9 8c0 7.1 4.3 13.6 10.8 16.4l98.7 42.3c1.7 .7 3.4 1.1 5.2 1.1c7.3 0 13.3-5.9 13.3-13.3V304h48c44.2 0 80-35.8 80-80V192c0-8.8-7.2-16-16-16H320l-7.2-14.3c-5.4-10.8-16.5-17.7-28.6-17.7H240v64c0 35.3-28.7 64-64 64c-5.2 0-10.3-.6-15.1-1.8zM160 144c-8.8 0-16 7.2-16 16v48c0 17.7 14.3 32 32 32s32-14.3 32-32V176 144h-8H160zm96 48a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z',
    "solidshield": 'M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM160.9 286.2c4.8 1.2 9.9 1.8 15.1 1.8c35.3 0 64-28.7 64-64V160h44.2c12.1 0 23.2 6.8 28.6 17.7L320 192h64c8.8 0 16 7.2 16 16v32c0 44.2-35.8 80-80 80H272v50.7c0 7.3-5.9 13.3-13.3 13.3c-1.8 0-3.6-.4-5.2-1.1l-98.7-42.3c-6.6-2.8-10.8-9.3-10.8-16.4c0-2.8 .6-5.5 1.9-8l15-30zM160 160h40 8v32 32c0 17.7-14.3 32-32 32s-32-14.3-32-32V176c0-8.8 7.2-16 16-16zm128 48a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z',
    "solidcertificate": 'M352 0H128V134.7l23.6-9.7 22.5-9.2L189 135.1l15.6 20.2 25.3 3.4 24.1 3.3 3.3 24.1 3.4 25.3L280.9 227l19.2 14.9L291 264.4 281.3 288l9.7 23.6 9.2 22.5L280.9 349l-20.2 15.6-3.4 25.3L254 414l-24.1 3.3-5.9 .8V512v0H512V160H352V0zm32 0V128H512L384 0zM92.3 154.6l-3.7 4.8L68.6 185.2l-32.2 4.4-6 .8-.8 6-4.4 32.2L-.5 248.5l-4.8 3.7 2.3 5.6L9.3 288-3 318.1l-2.3 5.6 4.8 3.7 25.7 19.9 4.4 32.2 .8 6 6 .8L64 390.2V512l64-40 64 40V390.2l27.6-3.8 6-.8 .8-6 4.4-32.2 25.7-19.9 4.8-3.7-2.3-5.6L246.7 288 259 257.9l2.3-5.6-4.8-3.7-25.7-19.9-4.4-32.2-.8-6-6-.8-32.2-4.4-19.9-25.7-3.7-4.8-5.6 2.3L128 169.3 97.9 157l-5.6-2.3zM64 288a64 64 0 1 1 128 0A64 64 0 1 1 64 288z',
    "regularqrcode": 'M48 80v96h96V80H48zM0 32H48h96 48V80v96 48H144 48 0V176 80 32zM48 336v96h96V336H48zM0 288H48h96 48v48 96 48H144 48 0V432 336 288zM400 80H304v96h96V80zM304 32h96 48V80v96 48H400 304 256V176 80 32h48zm80 416v32H352V448h32zm32 0h32v32H416V448zm32-160v32 96H352V384H320v96H256V384 288h96v32h64V288h32zM120 104v48H72V104h48zM72 360h48v48H72V360zM376 104v48H328V104h48z',
    "thinqrcode": 'M160 64V192H32V64H160zM32 32H0V64 192v32H32 160h32V192 64 32H160 32zM160 320V448H32V320H160zM32 288H0v32V448v32H32 160h32V448 320 288H160 32zM288 64H416V192H288V64zM256 32V64 192v32h32H416h32V192 64 32H416 288 256zm0 256v16V464v16h32V464 320h32v68 16h16 96 16V388 304 288H416v16 68H352V304 288H336 272 256zM120 104H72v48h48V104zM72 360v48h48V360H72zM376 104H328v48h48V104zM320 432v48h48V432H320zm128 0H400v48h48V432z',
    "solidbadgecheck": 'M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209l17-17L352 158.1l-17 17-111 111-47-47-17-17L126.1 256l17 17 64 64 17 17 17-17L369 209z',
    "solidmobilenotch": 'M128 64V80c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V64h32c8.8 0 16 7.2 16 16V432c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zM96 0C51.8 0 16 35.8 16 80V432c0 44.2 35.8 80 80 80H288c44.2 0 80-35.8 80-80V80c0-44.2-35.8-80-80-80H96zm32 400c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H144c-8.8 0-16 7.2-16 16z',
  }

  const iconStyling = {
    "solidmobilenotch": {
      "marginLeft": "12px",
      "marginTop": "-3px",
    },
    "thinqrcode": {
      "marginLeft": "8px",
      "marginTop": "-3px",
    },
    "solidbadgecheck": {
      "marginTop": "-3px",
      "marginLeft": "1px"
    },
    "regularshield": {
      "marginTop": "-2px",
      "marginLeft": "2px"
    }
  }

  const data = [
    {
      "icon": "fa",
      "svg": "regularshield",
      "title": FEATURES_CONTENT.DIGITAL_ID_LABEL,
      "text": FEATURES_CONTENT.DIGITAL_ID_TEXT
    },
    {
      "icon": "fa",
      "svg": "thinqrcode",
      "title": FEATURES_CONTENT.LIVE_VERIFICATION_LABEL,
      "text": FEATURES_CONTENT.LIVE_VERIFICATION_TEXT
    },
    {
      "icon": "fa",
      "svg": "solidmobilenotch",
      "title": FEATURES_CONTENT.MOBILE_DEVICE_LABEL,
      "text": FEATURES_CONTENT.MOBILE_DEVICE_TEXT
    },
    {
      "icon": "fa",
      "svg": "solidbadgecheck",
      "title": FEATURES_CONTENT.SATISFACTION_LABEL,
      "text": FEATURES_CONTENT.SATISFACTION_TEXT
    }
  ]

  return (
    <section id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{FEATURES_CONTENT.HEADER}</h2>
        </div>
        <div className='row'>
          {data?.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}>
                    <svg style={{...iconStyling[d.svg]}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d={icons[d.svg]} />
                    </svg>
                  </i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))}
        </div>
      </div>
    </section>
  )
}

export default Features; 
