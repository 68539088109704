import { Box } from "core-library/Box/Box";
import VerificationTag from "./assets/VerificationTag.png";
import ExpirationTag from "./assets/ExpirationTag.png";
import PolygonLeft from "./assets/PolygonLeft.png";
import { Text } from "core-library/Text/Text";
import { Flex } from "core-library/Flex/Flex";
import VaccinationIcon from "./assets/VaccinationIcon.png";
import ModalCloseIcon from "./assets/ModalCloseIcon";
import { useNavigate } from "react-router-dom";
import { VERBIAGE_LABEL, VERIFICATION_CONTENT } from "utilities/constants";

export default function VerififcationDetail({ image, serviceDog, expired }) {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        justifyContent={"flex-end"}
        background={"#fff"}
        pr="10px"
        pt="10px"
        cursor="pointer"
        onClick={() => navigate("/")}
      >
        <ModalCloseIcon />
      </Flex>
      <Box
        background={"#fff"}
        padding="30px 40px 40px"
        overflowX={"hidden"}
        position="relative"
      >
        <Box
          border="2px solid "
          style={{
            borderImage: "linear-gradient(to bottom, #add2ff, #6676ff) 1",
          }}
          className="miamiContainer"
        >
          <Box>
            <Text
              textTransform="capitalize"
              fontWeight={600}
              color="#505560"
              mb="10px"
              className="dogName"
            >
              {serviceDog?.Name?.split(" ")[0] || VERBIAGE_LABEL.DEFAULT_DOG_NAME}
              <Text
                as="span"
                ml="15px"
                color={expired ? "#EF3B13" : "#FEC163"}
                fontWeight={600}
                className="dogStatus"
              >
                {expired ? VERBIAGE_LABEL.EXPIRED : VERBIAGE_LABEL.EXCLUSIVE}
              </Text>
            </Text>
            <Text
              fontSize={"21px"}
              fontWeight={400}
              color="#505560"
              opacity={expired ? "0.2" : "0.7"}
              mb="30px"
            >
              {VERIFICATION_CONTENT.PERFORMED_AS}
              <Text as="span" color="#000" fontWeight={700}>
                {VERIFICATION_CONTENT.SEIZURE_ALERTING}
              </Text>
            </Text>
            <Flex
              background={"#F4F4F4"}
              borderRadius={"120px"}
              gap="10px"
              alignItems={"center"}
              mb="25px"
              opacity={expired && "0.5"}
              className="vaccinationTag"
            >
              <img
                src={VaccinationIcon}
                alt="VaccinationIcon"
                style={{ opacity: expired ? "0.5" : "" }}
              />
              <Text color="#000" opacity={expired && "0.5"}>
                {VERIFICATION_CONTENT.MIAMIN_TEXT}
              </Text>
            </Flex>
            <Text
              fontWeight={400}
              color="#505560"
              opacity={expired ? "0.2" : "0.7"}
              className="content"
            >
              {VERIFICATION_CONTENT.DUMMY_TEXT}
            </Text>
            <Flex
              className="content"
              height="1px"
              background={"#505560"}
              opacity={expired ? "0.2" : "0.19"}
              my="30px"
            />
            <Flex
              flexDirection={"column"}
              gap="20px"
              className="content"
              pb="150px"
              opacity={expired ? "0.2" : ""}
            >
              <Flex alignItems={"center"} className="items">
                <Text
                  className="detailHeading"
                  color="#000"
                  fontWeight={600}
                  opacity={0.7}
                  width={"50%"}
                >
                  {VERIFICATION_CONTENT.DOG_ID}
                </Text>
                <Text
                  className="detailContent"
                  color="#505560"
                  fontWeight={400}
                  opacity={0.7}
                >
                  {serviceDog?.ID || VERIFICATION_CONTENT.DEFAULT_ID}
                </Text>
              </Flex>
              <Flex alignItems={"center"} className="items">
                <Text
                  className="detailHeading"
                  color="#000"
                  fontWeight={600}
                  opacity={0.7}
                  width={"50%"}
                >
                  {VERBIAGE_LABEL.AGE}
                </Text>
                <Text
                  className="detailContent"
                  color="#505560"
                  fontWeight={400}
                  opacity={0.7}
                >
                  {VERIFICATION_CONTENT.DEFAULT_AGE}
                </Text>
              </Flex>
              <Flex alignItems={"center"} className="items">
                <Text
                  className="detailHeading"
                  color="#000"
                  fontWeight={600}
                  opacity={0.7}
                  width={"50%"}
                >
                  {VERBIAGE_LABEL.COLOR}
                </Text>
                <Text
                  className="detailContent"
                  color="#505560"
                  fontWeight={400}
                  opacity={0.7}
                >
                  {VERIFICATION_CONTENT.DEFAULT_COLOR}
                </Text>
              </Flex>
              <Flex alignItems={"center"} className="items">
                <Text
                  className="detailHeading"
                  color="#000"
                  fontWeight={600}
                  opacity={0.7}
                  width={"50%"}
                >
                  {VERIFICATION_CONTENT.OWNERS_HANDLERS}
                </Text>
                <Text
                  className="detailContent"
                  color="#505560"
                  fontWeight={400}
                  opacity={0.7}
                >
                  {VERBIAGE_LABEL.DEFAULT_OWNER_NAME}
                </Text>
              </Flex>
              <Flex alignItems={"center"} className="items">
                <Text
                  className="detailHeading"
                  color="#000"
                  fontWeight={600}
                  opacity={0.7}
                  width={"50%"}
                >
                  {VERIFICATION_CONTENT.OWNER_VERIFICATION}
                </Text>
                <Text
                  className="detailContent"
                  color="#505560"
                  fontWeight={400}
                  opacity={0.7}
                >
                  {VERBIAGE_LABEL.AVAILABLE}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
        <Box
          position="absolute"
          background={"#fff"}
          border={`3px solid ${expired ? "#EF3B13" : "#FEC163"}`}
          zIndex={1}
          padding={"15px 20px"}
          className="imageContainer"
        >
          <img
            src={image}
            width="100%"
            height={"100%"}
            alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
            style={{ position: "relative" }}
          />
          <img
            src={expired ? ExpirationTag : VerificationTag}
            alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
            className="imageTag"
          />
        </Box>
        <img
          src={PolygonLeft}
          alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
          height="120px"
          className="polygonLeft"
        />
        <img
          src={PolygonLeft}
          alt={VERBIAGE_LABEL.SERVICE_DOG_IMAGE}
          className="polygonBottom"
          style={{ position: "absolute" }}
        />
      </Box>
    </>
  );
}
