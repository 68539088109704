import * as React from "react";

function IdIcon(props) {
  return (
    <svg
      width={props?.size || "20px"}
      height={props?.size || "20px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M9.73 17.5a.84.84 0 01-.615-.26.84.84 0 01-.26-.615v-4.646L5.27 14.063a.923.923 0 01-.667.083.76.76 0 01-.52-.396l-.271-.48a.923.923 0 01-.084-.666.76.76 0 01.396-.52L7.708 10 4.104 7.917a.797.797 0 01-.406-.532.797.797 0 01.094-.656l.312-.479a.93.93 0 01.532-.396.783.783 0 01.656.083l3.562 2.105V3.375a.84.84 0 01.26-.615.84.84 0 01.615-.26h.542a.84.84 0 01.614.26.84.84 0 01.26.615v4.667l3.563-2.104a.783.783 0 01.657-.084c.229.07.406.202.53.396l.313.48a.797.797 0 01.094.655.797.797 0 01-.406.532L12.292 10l3.583 2.083a.797.797 0 01.406.532.797.797 0 01-.093.656l-.292.479a.93.93 0 01-.531.396.783.783 0 01-.657-.084l-3.562-2.083v4.646a.84.84 0 01-.26.615.84.84 0 01-.615.26h-.542z"
          fill="#004369"
        />
      </g>
    </svg>
  );
}

export default IdIcon;
