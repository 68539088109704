import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: {
    email: "",
    isLoggedIn: false,
  },
  guestEmailAddress: "",
  checkoutUrl: "",
};

export const LoginReducerSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.loggedIn = action.payload;
    },
    setGuestEmailData: (state, action) => {
      state.guestEmailAddress = action.payload;
    },
    setCheckoutUrlData: (state, action) => {
      state.checkoutUrl = action.payload;
    },
  },
});
export const { setLoginData, setGuestEmailData, setCheckoutUrlData } = LoginReducerSlice.actions;
export const LoginState = (state) => state.loginState;

export default LoginReducerSlice.reducer;
