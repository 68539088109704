import { Flex } from "core-library/Flex/Flex";
import Loading from "components/Magic/Loading";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Text } from "core-library/Text/Text";
import FormikInput from "core-library/FormikInput";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setApplicationDogDetailsData } from "reducers/ApplicationReducer";
import { Formik, Form } from "formik";
import * as yup from "yup";
import goToCheckout from "utilities/goToCheckout";
import { useLocation } from "react-router-dom";
import { APPLICATION_CONTENT, VALIDATIONS, VERBIAGE_LABEL } from "utilities/constants";
import { setCheckoutUrlData } from "reducers/LoginReducer";

const AddServiceTypeValidationSchema = yup.object().shape({
  dog_name: yup.string().trim().required(VALIDATIONS.REQUIRED),
});

export default function Step4({ setStep }) {
  const [submitting, setSubmitting] = useState(false);
  const [snackBarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: null,
  });
  const { open, vertical, horizontal } = snackBarState;
  const dispatch = useDispatch();
  const location = useLocation();
  const dogDetails = useSelector(
    (state) => state?.applicationState?.dogDetails
  );
  const serviceType = useSelector(
    (state) => state?.applicationState?.serviceType
  );
  const handlerEmail = useSelector(
    (state) => state?.applicationState?.handlerEmail
  );
  const signedUrl = useSelector((state) => state?.applicationState?.signedUrl);
  const loginData = useSelector((state) => state?.loginState?.loggedIn);
  const pricesData = useSelector((state) => state?.pricesDataState?.pricesData);
  const priceDefault = pricesData?.filter((priceItem) => priceItem?.default);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      {submitting
        ? <Loading />
        : (

          <Formik
            initialValues={{
              dog_name: dogDetails?.dog_name || "",
              handler_name: dogDetails.handler_name || "",
              dog_color: dogDetails?.dog_color || "",
              dog_breed: dogDetails?.dog_breed || "",
              dog_age: dogDetails?.dog_age || "",
              dog_description: dogDetails?.dog_description || "",
            }}
            onSubmit={() => { }}
            enableReinitialize
            validationSchema={AddServiceTypeValidationSchema}
          >
            {({ values }) => {
              return (
                <Form>
                  <Flex
                    background={"#fff"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    height={"80%"}
                  >
                    <Flex width="100%" p="20px" justifyContent="center">
                      <Flex flexDirection={"column"}>
                        <Text
                          color="#051226"
                          fontSize={"20px"}
                          fontWeight={600}
                          fontFamily={"SF Pro Display"}
                        >
                          {APPLICATION_CONTENT.SERVICE_DOG_DETAILS_TEXT}
                        </Text>

                        <Flex flexDirection={"column"} gap="16px" mt="30px" mb="50px">
                          <FormikInput
                            label={`${APPLICATION_CONTENT.SERVICE_DOG_NAME}*`}
                            name="dog_name"
                            variant="outlined"
                          />
                          <FormikInput
                            label={`${APPLICATION_CONTENT.HANDLER_NAME}*`}
                            name="handler_name"
                            variant="outlined"
                          />
                          <FormikInput
                            label={APPLICATION_CONTENT.SERVICE_DOG_COLOR}
                            name="dog_color"
                            variant="outlined"
                          />
                          <FormikInput
                            label={APPLICATION_CONTENT.SERVICE_DOG_AGE}
                            name="dog_age"
                            type="number"
                            variant="outlined"
                          />
                          <FormikInput
                            label={APPLICATION_CONTENT.SERVICE_DOG_BREED}
                            name="dog_breed"
                            variant="outlined"
                          />
                          <FormikInput
                            label={APPLICATION_CONTENT.SERVICE_DOG_DESCRIPTION}
                            name="dog_description"
                            variant="outlined"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      gap="20px"
                      py="30px"
                      borderTop={"1px solid #D79791"}
                      justifyContent={"center"}
                    >
                      <Flex
                        width={"160px"}
                        height={"60px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        cursor="pointer"
                        borderRadius={"6px"}
                        border={"1px solid #D6BCB9"}
                        fontSize={"18px"}
                        fontFamily={"Roboto"}
                        fontWeight={600}
                        color="#832F27"
                        onClick={() => setStep(3)}
                      >
                        {VERBIAGE_LABEL.BACK}
                      </Flex>
                      <Flex
                        width={"200px"}
                        height={"60px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        cursor={(!(values?.dog_name && values?.handler_name) || submitting) ? "not-allowed" : "pointer"}
                        borderRadius={"6px"}
                        border={"1px solid #BF7F79"}
                        background={"#F2E5E4"}
                        fontSize={"18px"}
                        fontFamily={"Roboto"}
                        fontWeight={600}
                        color="#832F27"
                        onClick={async () => {
                          setSubmitting(true)
                          if (values?.dog_name && values?.handler_name) {
                            dispatch(setApplicationDogDetailsData(values));
                            const data = {
                              email: loginData?.email || handlerEmail,
                              imageBucket: signedUrl.fields.bucket,
                              imageKey: signedUrl.fields.key,
                              name: values?.dog_name,
                              ownerName: values?.handler_name,
                              age: values?.dog_age,
                              breed: values?.dog_breed,
                              service: serviceType,
                              color: values?.dog_color,
                              description: values?.dog_description,
                            };
                            try {
                              const resp = await goToCheckout(data, ...priceDefault, location.state);
                              //console.log(resp?.url, 'response from', localStorage?.getItem('url'))
                              dispatch(setCheckoutUrlData(resp?.url))
                            } catch (err) {
                              setSubmitting(false)
                              setSnackbarState({
                                ...snackBarState,
                                open: true,
                                message: err
                              });
                            }
                          } else {
                            setSubmitting(false)
                          }
                        }}
                        opacity={(!(values?.dog_name && values?.handler_name) || submitting) && "0.4"}
                      >
                        {VERBIAGE_LABEL.SAVE}
                      </Flex>
                    </Flex>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        )
      }
    </>
  );
}
