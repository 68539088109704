import * as React from "react";

function NonVerifiedIcon(props) {
  return (
    <svg
      width={props?.size || "35px"}
      height={props?.size || "34px"}
      viewBox="0 0 35 34"
      fill="none"
      {...props}
    >
      <path
        d="M35 17c0 1.264-1.882 2.301-2.152 3.483-.279 1.222.957 2.966.422 4.068-.543 1.12-2.692 1.244-3.465 2.206-.772.961-.421 3.075-1.394 3.847-.972.772-2.955-.041-4.082.498-1.107.532-1.707 2.587-2.934 2.865-1.19.269-2.623-1.318-3.895-1.318-1.272 0-2.705 1.587-3.895 1.318-1.229-.277-1.827-2.332-2.936-2.864-1.126-.54-3.114.268-4.082-.5-.972-.771-.618-2.88-1.394-3.847-.775-.966-2.92-1.085-3.464-2.205s.702-2.847.422-4.068C1.871 19.263 0 18.264 0 17c0-1.265 1.882-2.302 2.152-3.484.279-1.222-.957-2.966-.422-4.068.543-1.12 2.692-1.244 3.465-2.205.772-.962.421-3.076 1.394-3.848.972-.772 2.955.041 4.082-.498 1.126-.54 1.707-2.588 2.934-2.866 1.228-.279 2.623 1.318 3.895 1.318 1.272 0 2.705-1.587 3.895-1.318 1.19.268 1.827 2.332 2.936 2.864 1.126.54 3.114-.268 4.082.499.967.767.618 2.88 1.394 3.847.775.967 2.92 1.086 3.464 2.206.534 1.102-.702 2.847-.422 4.068.27 1.183 2.152 2.22 2.152 3.483L35 17z"
        fill="#D30000"
      />
      <g stroke="#fff" strokeWidth={2} strokeMiterlimit={10}>
        <path d="M10.427 9.967l14.146 14.066M24.573 9.967L10.427 24.033" />
      </g>
    </svg>
  );
}

export default NonVerifiedIcon;
