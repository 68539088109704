import { useState, useEffect } from "react";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import VideoHeader from "./components/VideoHeader";
import Features from "./components/Features";
import About from "./components/About";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import Testimonials from "./components/Testimonials";
import Team from "./components/Team";
import Products from "./components/Products";
import Contact from "./components/Contact";
import JsonData from "./data.json";
import SmoothScroll from "smooth-scroll";
import "./Home.css";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  useEffect(() => {
    const scrollFunction = (event) => {
      let items = document.querySelectorAll("#menu .page-scroll");
      let nav = document.querySelector("nav#menu");
      let navBottom = nav?.getBoundingClientRect()?.bottom;
      let videoBottom = document
        .querySelector("video")
        ?.getBoundingClientRect()?.bottom;
      let contactSectionTop = document
        .querySelector("section#contact")
        ?.getBoundingClientRect()?.top;
      if (videoBottom < navBottom) {
        if (contactSectionTop < navBottom - 51) {
          items.forEach((i) => {
            i.style.color = "white";
            i.style.background = "unset";
            i.style["-webkit-background-clip"] = "unset";
            i.style["-webkit-text-fill-color"] = "unset";
          });
        } else {
          items.forEach((i) => {
            i.style.background =
              "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)";
            i.style["-webkit-background-clip"] = "text";
            i.style["-webkit-text-fill-color"] = "transparent";
            i.style.transition = "all .5s";
            nav.style["background-color"] = "rgba(0, 0, 0, 0.75)";
          });
        }
      } else {
        items.forEach((i) => {
          i.style.color = "white";
          i.style.background = "unset";
          i.style["-webkit-background-clip"] = "unset";
          i.style["-webkit-text-fill-color"] = "unset";
          nav.style["background-color"] = "unset";
        });
      }
    };
    window.addEventListener("scroll", scrollFunction);
    return () => window.removeEventListener("scroll", scrollFunction);
  }, []);

  return (
    <div>
      <Navigation />
      {/* <VideoHeader data={landingPageData.Header} /> */}
      <Header/>
      <Features />
      <Products />
      {/* <About data={landingPageData.About} /> */}
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Gallery /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      <Contact/>
    </div>
  );
};

export default Home;
