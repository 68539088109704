import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { API_URL } from "api/const";
import { useNavigate } from "react-router-dom";
import "./MiamiServiceDogs.css";
import LoaderCard from "./LoaderCard";
import LoadingCard from "../DefaultFlow/LoadingCard";
import VerififcationDetail from "./VerififcationDetail";
import { VERBIAGE_LABEL, VERIFICATION_CONTENT, ACCESSOR } from "utilities/constants";
import { API_URLS } from "utilities/constants/api";

const serviceDogDisplay = (serviceDog) => {
  return {
    [VERBIAGE_LABEL.NAME]: serviceDog?.[ACCESSOR.NAME] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.SERVICE]: serviceDog?.[ACCESSOR.SERVICE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.AGE]: serviceDog?.[ACCESSOR.AGE] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.COLOR]: serviceDog?.[ACCESSOR.COLOR] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.DESCRIPTION]: serviceDog?.[ACCESSOR.DESCRIPTION] || VERBIAGE_LABEL.UNKNOWN,
    [VERBIAGE_LABEL.ID]: serviceDog?.[ACCESSOR.SK] || VERBIAGE_LABEL.UNKNOWN,
  };
};

export default function MiamiServiceDogs() {
  const navigate = useNavigate();
  const [serviceDog, setServiceDog] = useState();
  const [image, setImage] = useState();
  const [expired, setExpired] = useState(false);
  const [snackBarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { open, vertical, horizontal } = snackBarState;
  const [loadingData, setLoadingData] = useState(true);
  const [dataFetch, setDataFetch] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingData(false);
    }, 3500);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}${API_URLS.VERIFY}/${id}`);
        const serviceDog = await response.json();
        setServiceDog(serviceDogDisplay(serviceDog));
        setImage(serviceDog?.imageUrl);
        setDataFetch(true);
      } catch (e) {
        setDataFetch(false);
        setTimeout(() => {
          setSnackbarState({
            ...snackBarState,
            open: true,
          });
        }, 3100);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() => navigate("/")}
      >
        <Alert
          onClose={() => navigate("/")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {VERIFICATION_CONTENT.DEFAULT_FLOW_ERROR_TEXT}:{" "}
          {location.pathname.split("/")[2]}
        </Alert>
      </Snackbar>

      {loadingData ? (
        <LoaderCard />
      ) : dataFetch ? (
        <VerififcationDetail
          image={image}
          serviceDog={serviceDog}
          expired={expired}
        />
      ) : (
        <div className="retrieveCard2">
          <LoadingCard textContent={""} />
        </div>
      )}
    </>
  );
}
