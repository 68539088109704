import React, { FC } from "react";
import { TextField as MaterialTextField } from "@mui/material";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme as AugmentedTheme, TextFieldProps } from "@mui/material";
import { get } from "lodash";

export const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    root: {
      border: (props: FieldProps) =>
        props.colour
          ? `0.1rem solid ${get<Record<string, string>, string>(
              theme.palette as any,
              props.colour
            )}`
          : "0.1rem solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "#fcfcfb",
      transition: theme?.transitions?.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    input: {
      "& input::placeholder": {
        fontSize: "1.4rem",
        fontFamily: "Roboto",
        letterSpacing: "0.025rem",
        fontStyle: "oblique",
        color: "rgba(255, 255, 255, 0.6);",
      },
    },
  })
);

export interface FieldProps {
  colour?: string;
}

export const TextField: FC<TextFieldProps & FieldProps> = (props) => {
  const classes = useStyles(props);
  return props?.colour ? (
    <MaterialTextField
      //@ts-ignore
      InputProps={{ classes, disableUnderline: true }}
      {...props}
    />
  ) : (
    <MaterialTextField className={classes.input} {...props} />
  );
};
