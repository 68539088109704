import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Typography from '@mui/material/Typography';
import { VERBIAGE_LABEL } from 'utilities/constants';
import { ROUTE_PATHNAME } from 'App';
import { API_KEYS } from 'utilities/constants/keys';

const ServiceDogCard = ({ dog }) => {
  const navigate = useNavigate();
  const viewDocument = () => {
    navigate(`${ROUTE_PATHNAME.DOCUMENTS}/${dog.sk}`)
  }
  return (
    <Card sx={{cursor: 'pointer'}}>
      <CardActionArea onClick={viewDocument}>
        <CardMedia
          sx={{ height: 140 }}
          image={dog?.[API_KEYS.IMAGE_URL]}
          title={dog?.[API_KEYS.NAME]}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {dog?.[API_KEYS.NAME]}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dog?.[API_KEYS.DESCRIPTION]}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button 
          variant="contained"
          startIcon={<QrCodeScannerIcon />} 
          onClick={viewDocument} 
          size="small"
        >
          {VERBIAGE_LABEL.VIEW_QR_CODE}
        </Button>
      </CardActions>
    </Card>
  );
}

export default ServiceDogCard
