import React, { FC, useMemo, useCallback } from "react";
import { useFormikContext } from "formik";

import { useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import { makeStyles, createStyles } from "@material-ui/styles";
import { TextFieldProps } from "@mui/material";
import TextField from "core-library/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";

export interface InputFieldProps {
  label?: string;
  error?: string;
  warning?: string;
  labelHide?: boolean;
}

export interface FormikInputProps extends InputFieldProps {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  borderradius?: string;
  adornmentValue?: string;
  onChange?: (e: any) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: (props: FormikInputProps) => props.borderradius,
    },
    timeinput: {
      ".MuiFilledInput-adornedEnd::focused": {
        display: "none",
      },
    },
  })
);

export const FormikInput: FC<FormikInputProps & TextFieldProps> = ({
  name,
  label,
  placeholder,
  type,
  adornmentValue,
  onChange,
  ...props
}) => {
  const isPassword = useMemo(() => type === "password", [type]);
  const [isOpen, setOpen] = useState(false);
  const { errors, touched, handleChange, setFieldTouched, handleBlur, values } =
    useFormikContext();

  const handleInputChange = useCallback(
    (e: any) => {
      e.persist();
      handleChange(e);
      if (onChange) {
        onChange(e);
      }
    },
    [handleChange, onChange]
  );

  const error = errors[name as keyof typeof errors];

  const blur = (name: string, e: any) => {
    e.persist();
    handleBlur(e);
    setFieldTouched(name, true, false);
  };

  const [val, setVal] = useState(false);

  // @ts-ignore
  const classes = useStyles(props);
  return (
    <>
      <TextField
        type={isPassword && isOpen ? "text" : type}
        id={name}
        name={name}
        // @ts-ignore
        value={values[name]}
        helperText={touched[name as keyof typeof touched] && error ? error : ""}
        error={
          touched[name as keyof typeof touched] &&
          Boolean(errors[name as keyof typeof errors])
        }
        label={label}
        placeholder={placeholder}
        fullWidth
        onChange={(e: any) => handleInputChange(e)}
        onBlur={blur.bind(null, name)}
        {...props}
        focused={val}
        onFocus={() => {
          setVal(true);
        }}
        InputLabelProps={
          type === "date"
            ? {
                shrink: true,
              }
            : {}
        }
        InputProps={
          isPassword
            ? {
                endAdornment: (
                  <InputAdornment
                    onClick={() => setOpen(!isOpen)}
                    position="end"
                  >
                    {isOpen ? (
                      <VisibilityIcon
                        color={
                          touched[name as keyof typeof touched]
                            ? !Boolean(errors[name as keyof typeof errors])
                              ? "primary"
                              : "error"
                            : "inherit"
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        color={
                          touched[name as keyof typeof touched]
                            ? !Boolean(errors[name as keyof typeof errors])
                              ? "primary"
                              : "error"
                            : "inherit"
                        }
                      />
                    )}
                  </InputAdornment>
                ),
              }
            : type === "searchfield"
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
                classes: {
                  root: classes.root,
                },
              }
            : type === "date"
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <EventIcon color="disabled" />
                  </InputAdornment>
                ),
              }
            : type === "timeinput" && val
            ? {
                endAdornment: (
                  <InputAdornment position="end" className={classes.timeinput}>
                    <span
                      style={{
                        color: "rgba(0, 204, 102, 0.6)",
                        fontSize: "1.6rem",
                        letterSpacing: "0.05rem",
                        fontFamily: "Roboto",
                      }}
                    >
                      {adornmentValue}
                    </span>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    </>
  );
};
